import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

window.onmessage = event => {
  if (!event?.data?.token) {
    return;
  }

  window.localStorage.setItem(`onboard-token`, event.data.token);
  window.localStorage.setItem(`verificationType`, event.data.verificationType);

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CompletedOnboardingComponent } from '../components/completed-onboarding/completed-onboarding.component';
import { DocumentPreviewComponent } from '../components/document-preview/document-preview.component';
import { CloseAppService } from './close-app.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRef!: MatDialogRef<any>;

  constructor(
    public dialog: MatDialog,
    private closeApp: CloseAppService,
  ) {}

  open(previewData: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'document-preview';

    dialogConfig.data = previewData;

    this.dialogRef = this.dialog.open(DocumentPreviewComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((result: any) => {
      //we can use a call back here
    });
  }

  openOnboardModal(data: { title: string; cta: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '285px';
    dialogConfig.data = data;

    this.dialogRef = this.dialog.open(CompletedOnboardingComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.closeApp.closeApp('DIALOG CLOSE');
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

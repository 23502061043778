import { EmploymentStatus } from '../services/onboarding.interface';

export interface SelectOption {
  label: string;
  value: string;
}

export interface EmploymentOption {
  name: string;
  value: EmploymentStatus;
}

export interface FinraOption {
  name: string;
  value:  boolean;
}

export const employmentOptionsArray: EmploymentOption[] = [
  {
    name: 'Employed',
    value: EmploymentStatus.EMPLOYED,
  },
  {
    name: 'Self-Employed',
    value: EmploymentStatus.SELF_EMPLOYED,
  },
  {
    name: 'Unemployed',
    value: EmploymentStatus.UNEMPLOYED,
  },
];

export const finraOptionArray: FinraOption[] = [
  {
    name: 'Yes, I am',
    value: true,
  },
  {
    name: `No, I'm not`,
    value: false,
  },
];

export const employmentTypesArray: SelectOption[] = [
  {
    label: 'Agriculture',
    value: 'AGRICULTURE',
  },
  {
    label: 'Mining',
    value: 'MINING',
  },
  {
    label: 'Utilities',
    value: 'UTILITIES',
  },
  {
    label: 'Construction',
    value: 'CONSTRUCTION',
  },
  {
    label: 'Manufacturing',
    value: 'MANUFACTURING',
  },
  {
    label: 'Wholesale Trade',
    value: 'WHOLESALE',
  },
  {
    label: 'Retail Trade',
    value: 'RETAIL',
  },
  {
    label: 'Transportation and Warehousing',
    value: 'TRANSPORT',
  },
  {
    label: 'Information',
    value: 'INFORMATION',
  },
  {
    label: 'Finance and Insurance',
    value: 'FINANCE',
  },
  {
    label: 'Real Estate and Rental and Leasing',
    value: 'REAL_ESTATE',
  },
  {
    label: 'Professional, Scientific, and Technical Services',
    value: 'PROFESSIONAL',
  },
  {
    label: 'Management of Companies and Enterprises',
    value: 'MANAGEMENT',
  },
  {
    label: 'Educational Services',
    value: 'EDUCATION',
  },
  {
    label: 'Health Care and Social Assistance',
    value: 'HEALTH',
  },
  {
    label: 'Arts, Entertainment, and Recreation',
    value: 'ART',
  },
  {
    label: 'Accommodation and Food Services',
    value: 'FOOD',
  },
  {
    label: 'Public Administration',
    value: 'PUBLIC',
  },
  {
    label: 'Waste Management and Remediation Services',
    value: 'WASTE',
  },
];

export const employmentPositionArray = [
  { value: 'ACCOUNTANT', name: 'Accountant/CPA/Bookkeeper/Controller' },
  { value: 'ACTUARY', name: 'Actuary' },
  { value: 'ADJUSTER', name: 'Adjuster' },
  { value: 'ADMINISTRATOR', name: 'Administrator' },
  { value: 'ADVERTISER', name: 'Advertiser/Marketer/PR Professional' },
  { value: 'AGENT', name: 'Agent' },
  { value: 'ATC', name: 'Air Traffic Controller' },
  { value: 'AMBASSADOR', name: 'Ambassador/Consulate Professional' },
  { value: 'ANALYST', name: 'Analyst' },
  { value: 'APPRAISER', name: 'Appraiser' },
  { value: 'ARCHITECT', name: 'Architect/Designer' },
  { value: 'ARTIST', name: 'Artist/Performer/Actor/Dancer' },
  { value: 'ASSISTANT', name: 'Assistant' },
  { value: 'ATHLETE', name: 'Athlete' },
  { value: 'ATTENDANT', name: 'Attendant' },
  { value: 'ATTORNEY', name: 'Attorney/Judge/Legal Professional' },
  { value: 'AUCTIONEER', name: 'Auctioneer' },
  { value: 'AUDITOR', name: 'Auditor' },
  { value: 'BARBER', name: 'Barber/Beautician/Hairstylist' },
  { value: 'BROKER', name: 'Broker' },
  { value: 'BUSINESS_EXEC', name: 'Business Executive (VP, Director, etc.)' },
  { value: 'BUSINESS_OWNER', name: 'Business Owner' },
  { value: 'CAREGIVER', name: 'Caregiver' },
  { value: 'CARPENTER', name: 'Carpenter/Construction Worker' },
  { value: 'CASHIER', name: 'Cashier' },
  { value: 'CHEF', name: 'Chef/Cook' },
  { value: 'CHIROPRACTOR', name: 'Chiropractor' },
  { value: 'CIVIL', name: 'Civil Servant' },
  { value: 'CLERGY', name: 'Clergy' },
  { value: 'CLERK', name: 'Clerk' },
  { value: 'COMPLIANCE', name: 'Compliance/Regulatory Professional' },
  { value: 'CONSULTANT', name: 'Consultant' },
  { value: 'CONTRACTOR', name: 'Contractor' },
  { value: 'COUNSELOR', name: 'Counselor/Therapist' },
  { value: 'CUSTOMER_SERVICE', name: 'Customer Service Representative' },
  { value: 'DEALER', name: 'Dealer' },
  { value: 'DEVELOPER', name: 'Developer' },
  { value: 'DISTRIBUTOR', name: 'Distributor' },
  { value: 'DOCTOR', name: 'Doctor/Dentist/Veterinarian/Surgeon' },
  { value: 'DRIVER', name: 'Driver' },
  { value: 'ENGINEER', name: 'Engineer' },
  { value: 'EXAMINER', name: 'Examiner' },
  { value: 'EXTERMINATOR', name: 'Exterminator' },
  { value: 'FACTORY', name: 'Factory/Warehouse Worker' },
  { value: 'FARMER', name: 'Farmer/Rancher' },
  { value: 'FINANCIAL', name: 'Financial Planner' },
  { value: 'FISHERMAN', name: 'Fisherman' },
  { value: 'FLIGHT', name: 'Flight Attendant' },
  { value: 'HR', name: 'Human Resources Professional' },
  { value: 'IMPEX', name: 'Importer/Exporter' },
  { value: 'INSPECTOR', name: 'Inspector/Investigator' },
  { value: 'INTERN', name: 'Intern' },
  { value: 'INVESTMENT', name: 'Investment Advisor/Investment Manager' },
  { value: 'INVESTOR', name: 'Investor' },
  { value: 'IT', name: 'IT Professional/IT Associate' },
  { value: 'JANITOR', name: 'Janitor' },
  { value: 'JEWELER', name: 'Jeweler' },
  { value: 'LABORER', name: 'Laborer' },
  { value: 'LANDSCAPER', name: 'Landscaper' },
  { value: 'LENDING', name: 'Lending Professional' },
  { value: 'MANAGER', name: 'Manager' },
  { value: 'MECHANIC', name: 'Mechanic' },
  { value: 'MILITARY', name: 'Military, Officer or Associated' },
  { value: 'MORTICIAN', name: 'Mortician/Funeral Director' },
  { value: 'NURSE', name: 'Nurse' },
  { value: 'NUTRITIONIST', name: 'Nutritionist' },
  { value: 'OFFICE', name: 'Office Associate' },
  { value: 'PHARMACIST', name: 'Pharmacist' },
  { value: 'PHYSICAL', name: 'Physical Therapist' },
  { value: 'PILOT', name: 'Pilot' },
  {
    value: 'POLICE',
    name: 'Police Officer/Firefighter/Law Enforcement Professional',
  },
  { value: 'POLITICIAN', name: 'Politician' },
  { value: 'PM', name: 'Project Manager' },
  { value: 'REP', name: 'Registered Rep' },
  { value: 'RESEARCHER', name: 'Researcher' },
  { value: 'SAILOR', name: 'Sailor/Seaman' },
  { value: 'SALES', name: 'Salesperson' },
  { value: 'SCIENTIST', name: 'Scientist' },
  { value: 'SEAMSTRESS', name: 'Seamstress/Tailor' },
  { value: 'SECURITY', name: 'Security Guard' },
  { value: 'SOCIAL', name: 'Social Worker' },
  { value: 'TEACHER', name: 'Teacher/Professor' },
  { value: 'TECHNICIAN', name: 'Technician' },
  { value: 'TELLER', name: 'Teller' },
  { value: 'TRADESPERSON', name: 'Tradesperson/Craftsperson' },
  { value: 'TRAINER', name: 'Trainer/Instructor' },
  { value: 'TRANSPORTER', name: 'Transporter' },
  { value: 'UNDERWRITER', name: 'Underwriter' },
  { value: 'WRITER', name: 'Writer/Journalist/Editor' },
];

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { OnboardingModule } from './onboarding/onboarding.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OnboardingModule,
    CoreModule,
    MatSnackBarModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3800,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      },
    },
    { provide: 'googleTagManagerId', useValue: environment.tagManager },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

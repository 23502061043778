export interface Bank {
  id: number;
  name: string;
  code: string;
  countryCode: string;
}

export interface AccountInfo {
  firstName: string;
  lastName: string;
  accountName: string;
}

export interface BVNUpdate {
  bvn: string;
  dob: string;
}

export interface KYCProfile {
  id: number;
  userId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  address: string;
  country: string;
  gender: string;
  maritalStatus: string;
  dob: string;
  bankAcctNo: string;
  bankAcctName: string;
  bvn?: any;
  pepStatus: boolean;
  bankId: string;
  portfolioType?: string;
  state?: string;
  pepNames?: any;
  employmentStatus?: EmploymentStatus;
  employmentCompany?: any;
  employmentPosition?: any;
  employedByBroker: boolean;
  employmentType?: any;
  email: string;
  directorOfPublicCo: any;
  annualIncome?: number;
  networthLiquid?: number;
  networthTotal?: number;
  idType?: any;
  photoIdUrl?: any;
  passportUrl?: any;
  profileImageUrl?: any;
  addressProofUrl?: any;
  addressType?: any;
  signatureUrl?: any;
  lg?: any;
  postalCode?: any;
  maidenName?: any;
  phoneNumber?: string;
  nextOfKin?: any;
  nextOfKinPhone?: any;
  cscsSent: boolean;
  cscsUploadState?: any;
  investmentObjective?: InvestmentObjective;
  investmentExperience?: InvestmentExperience;
  riskTolerance?: RiskTolerance;
  disclosureName?: any;
  globalDocumentUploadStatus?: any;
  nin?: any;
  ninFetchStatus?: any;
  bvnFetchStatus?: any;
  city: string;
  cscs: string;
  userKyc: KYCStatus[];
  fullName: string;
  isAffiliatedToBrokerOrFinra: string;
  isSeniorExecutive: string;
  isPoliticalFigure: string;
  isFamilyMemberOrRelativeOfPoliticalFigure: string;
  fundingSource: string;
  extraInvestmentInfo: any[];
  noneApplyExtraInvestmentInfo: boolean;
  acceptElectronicSignature: boolean;
  acceptNoneUsCitizen: boolean;
  employerAddress: string;
}

export interface NubanInfo {
  accountName: string;
  address: string;
  bvn: string;
  dob: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  lgaOfOrigin: string;
  middleName: string;
  nin: string;
  phoneNumber: string;
}

export interface NameLookupResponse {
  firstName: string;
  lastName: string;
  accountName: string;
  valid: boolean;
}

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export enum InvestmentObjective {
  LONG_TERM = 'LONG_TERM',
  INFREQUENT = 'INFREQUENT',
  FREQUENT = 'FREQUENT',
  ACTIVE_DAILY = 'ACTIVE_DAILY',
  NEW = 'NEW',
}

export enum RiskTolerance {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  SPECULATION = 'SPECULATION',
  HIGH = 'HIGH',
}

export enum InvestmentExperience {
  YRS_1_2 = 'YRS_1_2',
  YRS_3_5 = 'YRS_3_5',
  YRS_5_10 = 'YRS_5_10',
  YRS_10 = 'YRS_10',
}

export enum UploadType {
  PASSPORT = 'PASSPORT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_ADDRESS_REAR = 'PROOF_OF_ADDRESS_REAR',
  IDENTITY = 'IDENTITY',
  IDENTITY_REAR = 'IDENTITY_REAR',
  GENERIC = 'GENERIC',
  PHOTO_ID = 'PHOTO_ID',
  PHOTO_ID_BACK = 'PHOTO_ID_BACK',
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  NIN_SLIP_SHOWING_THE_ADDRESS = 'NIN_SLIP_SHOWING_THE_ADDRESS',
}

export enum SupportedDocTypeEnum {
  NATIONAL_IDENTITY_NUMBER = 'NATIONAL_IDENTITY_NUMBER',
}

export enum VerificationChoiceEnum {
  PAYOUT_ACCOUNT = 'PAYOUT_ACCOUNT',
  BVN = 'BVN',
}

export enum FilePosition {
  FRONT = 'front',
  REAR = 'rear',
}

export interface FileUpload {
  base64File?: File;
  file?: File;
  position: FilePosition;
  uploadType: UploadType;
  userId: string;
  productType?: string;
}

export interface FileUploadData {
  userId: string;
  uploadType: string;
  fileName: string;
  base64File: null;
}

export interface CSCSS {
  cscsCHN: string;
  userId?: string;
}

export interface BvnRequest {
  userId: string;
  bvn: string;
}

export interface Bvn {
  bvn: string;
  dob: string;
  mobile: string;
  firstName: string;
  lastName: string;
  formatted_dob: string;
  nin: string;
  passport: string;
}

export enum KycCountry {
  NGA = 'NGA',
  US = 'US',
}

export interface KYCStatus {
  id: number;
  userId: string;
  country: KycCountry;
  status: string;
  validations: Validation[];
}

export enum KYC {
  BANK = 'BANK',
  PHONE = 'PHONE',
  ADDRESS = 'ADDRESS',
  GENDER = 'GENDER',
  BVN = 'BVN',
  LG = 'LG',
}

export interface Validation {
  id: number;
  userId: string;
  kyc: KYC;
  status: string;
}

export interface NextKYC {
  completeSteps: number;
  totalSteps: number;
  steps: KYCSection[];
}

export interface KYCSection {
  section: string;
  fields: string[];
}

export interface IAcceptedDocuments {
  id: number;
  name: string;
  description: string;
  documentType: UploadType;
  dateCreated: string;
  dateModified: Date;
  modifiedBy: string;
  createdBy: string;
}

export interface IVerifyBvn {
  id: number;
  bvn: string;
  dob: string;
  mobile: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  nin: string;
  passport: string;
  dateCreated: Date;
  dateModified: Date;
}

export interface ITab {
  kyc: string;
  completed: boolean;
  tabIndex: {
    global: number | null;
    local: number | null;
    general: number | null;
    [key: string]: any;
  };
}

export interface KycItem {
  kyc: string;
  completed: boolean;
}

export interface ImissingKYC {
  general: KycItem[];
  global: KycItem[];
  local: KycItem[];
}

export enum VerificationTypes {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
  GENERAL = 'GENERAL',
  ALL = 'ALL',
  GENGLOBAL = 'GENGLOBAL',
  GENLOCAL = 'GENLOCAL',
}

export interface IEditPhone {
  dob: any;
  mobileNumber: any;
}

export interface IVerifyBank {
  accountNumber: string;
  bankAcctName?: string;
  dob?: string;
  bankId: number;
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, Validators, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, interval } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ICountryExtra } from 'src/app/shared/components/phone-verification/phone-verification.component';
import { PhoneNumberValidator } from 'src/app/shared/PhoneNumber.validator';
import { OnboardingStateService } from '../services/onboarding-state.service';
import { VerificationChoiceEnum } from '../services/onboarding.interface';
import { OnboardingService } from '../services/onboarding.service';
import { MissingKycStepService } from '../services/missing-kyc-step.service';

@Component({
  selector: 'phone-verification',
  templateUrl: './phone-verification.component.html',
})
export class PhoneVerificationComponent implements OnInit {
  otpControl: FormControl;
  otpConfig = {
    length: 6,
    isPasswordInput: true,
    containerClass: 'otpContainer',
    inputClass: 'otp-input',
    allowNumbersOnly: true,
  };
  choiceEnum = VerificationChoiceEnum;
  showPhoneNumber = false;
  numberPrefix = '+234';
  editPhoneForm: FormGroup;
  countDown$ = new BehaviorSubject<undefined | number>(undefined);
  maxDate: Date;

  constructor(
    public kyc: OnboardingStateService,
    private datePipe: DatePipe,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private kycService: OnboardingService,
    public missingKycStepService: MissingKycStepService,
  ) {
    this.otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

    this.matIconRegistry.addSvgIcon('edit-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit-icon.svg'));
    let validDate = new Date();
    this.maxDate = new Date(validDate.getUTCFullYear() - 18, validDate.getMonth(), validDate.getUTCDay());
    this.editPhoneForm = new FormGroup({
      dob: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', [Validators.required, PhoneNumberValidator('NG')]),
    });
  }

  get formValidity(): boolean {
    return this.otpControl.invalid || this.kyc.state.value.loading;
  }

  ngOnInit(): void {
    this.kyc.setSection('GENERAL');
    this.kyc.sendPhoneOTP();
    this.startOTPDelayTimer();
  }

  otpChange(otp: string) {
    if (otp.length == 6) {
      this.otpControl.patchValue(otp);
    } else {
      this.otpControl.patchValue('');
    }
  }

  startOTPDelayTimer(): void {
    const OTPRetryDelay = 45;
    const stop = () => this.countDown$.next(undefined);
    const count = (time: number) => this.countDown$.next(OTPRetryDelay - time);
    interval(1000).pipe(take(45)).pipe(tap()).subscribe(count, stop, stop);
  }

  changePhoneNumber() {
    let { dob, mobileNumber } = this.editPhoneForm.value;
    let payload = {
      dob: this.datePipe.transform(new Date(dob), 'yyyy-MM-dd'),
      mobileNumber,
    };
    this.kycService.changePhoneNumber(payload).subscribe(
      Response => this.kyc.sendPhoneOTP(),
      error => this.kyc.onError(error),
    );
  }

  receiveNumber(event: ICountryExtra | null) {
    if (event) this.editPhoneForm.get('mobileNumber')?.patchValue(event.phoneNumber);
  }

  submit() {
    this.kyc.confirmPhoneOTP(this.otpControl.value);
  }
}

import { Injectable } from '@angular/core';
import { KYCProfile } from 'src/app/onboarding/services/onboarding.interface';
import { ReqSuccessResponse } from '../http/api.interface';
import { BaseService } from '../http/base-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private client: BaseService) { }

  getUserByToken() {
    return this.client.get<ReqSuccessResponse<KYCProfile>>(
      '/api/v1/users/profile'
    );
  }
}

export interface AuthUserDetails {
  id: string;
  username: string;
  email: string;
  clientId: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  role: string;
}


export interface KYCProfile {
  id: number;
  userId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  address: string;
  country: string;
  gender: string;
  maritalStatus: string;
  dob: string;
  bankAcctNo: string;
  bankAcctName: string;
  bvn?: any;
  pepStatus: boolean;
  bankId: string;
  portfolioType?: string;
  state?: string;
  pepNames?: any;
  email?: string;
  employmentStatus?: EmploymentStatus;
  employmentCompany?: any;
  employmentPosition?: any;
  employedByBroker: boolean;
  employmentType?: any;
  directorOfPublicCo?: any;
  annualIncome?: number;
  networthLiquid?: number;
  networthTotal?: number;
  idType?: any;
  photoIdUrl?: any;
  passportUrl?: any;
  profileImageUrl?: any;
  addressProofUrl?: any;
  addressType?: any;
  signatureUrl?: any;
  lg?: any;
  postalCode?: any;
  maidenName?: any;
  nextOfKin?: any;
  nextOfKinPhone?: any;
  cscsSent: boolean;
  cscsUploadState?: any;
  investmentObjective?: InvestmentObjective;
  investmentExperience?: InvestmentExperience;
  riskTolerance?: RiskTolerance;
  disclosureName?: any;
  globalDocumentUploadStatus?: any;
  nin?: any;
  ninFetchStatus?: any;
  bvnFetchStatus?: any;
  city: string;
  cscs: string;
  userKyc: KYCStatus[];
  fullName: string;
}


export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export enum InvestmentObjective {
  LONG_TERM = 'LONG_TERM',
  INFREQUENT = 'INFREQUENT',
  FREQUENT = 'FREQUENT',
  ACTIVE_DAILY = 'ACTIVE_DAILY',
  NEW = 'NEW',
}

export enum RiskTolerance {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  SPECULATION = 'SPECULATION',
  HIGH = 'HIGH',
}

export enum InvestmentExperience {
  YRS_1_2 = 'YRS_1_2',
  YRS_3_5 = 'YRS_3_5',
  YRS_5_10 = 'YRS_5_10',
  YRS_10 = 'YRS_10',
}

export enum UploadType {
  PASSPORT = 'PASSPORT',
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  PHOTO_ID = 'PHOTO_ID',
  GENERIC = 'GENERIC',
}

export interface FileUpload {
  file: File;
  uploadType: UploadType;
  userId: string;
}
export interface FileUploadData {
  userId: string;
  uploadType: string;
  fileName: string;
  fileUrl: string;
  base64File: null;
}

export interface BvnRequest {
  userId: string;
  bvn: string;
}

export interface Bvn {
  bvn: string;
  dob: string;
  mobile: string;
  firstName: string;
  lastName: string;
  formatted_dob: string;
  nin: string;
  passport: string;
}

export enum KycCountry {
  NGA = 'NGA',
  US = 'US',
}

export interface KYCStatus {
  id: number;
  userId: string;
  country: KycCountry;
  status: string;
  validations: Validation[];
}

export enum KYC {
  BANK = 'BANK',
  PHONE = 'PHONE',
  ADDRESS = 'ADDRESS',
  GENDER = 'GENDER',
  BVN = 'BVN',
  LG = 'LG',
}

export interface Validation {
  id: number;
  userId: string;
  kyc: KYC;
  status: string;
}

export interface NextKYC {
  completeSteps: number;
  totalSteps: number;
  steps: KYCSection[];
}

export interface KYCSection {
  section: string;
  fields: string[];
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { BankStateService } from '../services/bank-state.service';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';

@Component({
  selector: 'bank-verification',
  templateUrl: './bank-verification.component.html',
})
export class BankVerificationComponent implements OnInit, OnDestroy {
  @Input() kycState: KycState = {} as KycState;
  bankForm: FormGroup;
  consent: FormControl = new FormControl('', Validators.required);
  accountSub: Subscription = new Subscription();

  constructor(
    public banks: BankStateService,
    public kyc: OnboardingStateService,
  ) {
    this.bankForm = new FormGroup({
      bankId: new FormControl('', [Validators.required]),
      bankAcctNo: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.required, Validators.pattern('[0-9]+')]),
      bankAcctName: new FormControl('', [Validators.required]),
    });
  }

  get formValidity(): boolean {
    return this.bankForm.invalid || this.consent.value != true || this.kyc.state.value.loading;
  }

  ngOnDestroy(): void {
    this.accountSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.kyc.getProfile();
    this.banks.list();
    this.createForm();
    this.accountSub = this.banks.state.pipe(skipWhile(state => state.loading)).subscribe(state => {
      this.bankForm.patchValue({
        bankAcctName: state.accountInfo?.accountName,
      });
    });
  }

  createForm() {
    this.bankForm = new FormGroup({
      bankId: new FormControl('', [Validators.required]),
      bankAcctNo: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.required, Validators.pattern('[0-9]+')]),
      bankAcctName: new FormControl('', [Validators.required]),
    });
  }

  getAccountName() {
    const { bankId, bankAcctNo } = this.bankForm.value;
    if (bankAcctNo.length == 10 && bankId && this.bankForm.get('bankAcctNo')?.valid) {
      let payload = {
        bankId,
        accountNumber: bankAcctNo,
      };
      this.banks.getBankInfo(payload);
    }
  }
}

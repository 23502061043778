import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseService {
  config = environment;
  TOKEN_LOCATION = `onboard-token`;

  constructor(public readonly http: HttpClient) {}

  token() {
    return localStorage.getItem(this.TOKEN_LOCATION);
  }

  updateToken(token: string) {
    localStorage.setItem(this.TOKEN_LOCATION, token);
  }

  deleteToken() {
    localStorage.removeItem(this.TOKEN_LOCATION);
  }

  cleanUrl(baseUrl: string, url: string) {
    let suffix = url[0] === '/' ? url.substr(1) : url;
    return `${baseUrl}/${suffix}`;
  }

  post<T>(
    endpoint: string,
    body: Record<string, any> = {},
    queryParams: Record<string, any> = {}
  ) {
    const url = this.cleanUrl(environment.enterpriseURL, endpoint);
    return this.http.post<T>(url, body, {
      params: {
        ...queryParams,
        productType: 'EQUITY',
      },
    });
  }

  put<T>(
    endpoint: string,
    body: Record<string, any> = {},
    params: Record<string, any> = {}
  ) {
    const url = this.cleanUrl(environment.enterpriseURL, endpoint);
    return this.http.put<T>(url, body, {
      params: { ...params, productType: 'EQUITY' },
    });
  }

  patch<T>(
    endpoint: string,
    data: Record<string, any> = {},
    params: Record<string, any> = {}
  ) {
    const url = this.cleanUrl(environment.enterpriseURL, endpoint);
    return this.http.patch<T>(url, data, {
      params: { ...params, productType: 'EQUITY' },
    });
  }

  get<T>(endpoint: string, params: Record<string, any> = {}) {
    const url = this.cleanUrl(environment.enterpriseURL, endpoint);
    return this.http.get<T>(url, {
      params: {
        ...params,
        productType: 'EQUITY',
      },
    });
  }

  delete<T>(
    endpoint: string,
    body: Record<string, any> = {},
    params: Record<string, any> = {}
  ) {
    const url = this.cleanUrl(environment.enterpriseURL, endpoint);
    return this.http.delete<T>(url, {
      params: { ...params, productType: 'EQUITY' },
      body: body || {},
    } as any) as any;
  }
}

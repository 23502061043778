import { Injectable } from '@angular/core';
import { OnboardingStateService } from '../../services/onboarding-state.service';
import { MissingKycStepService } from '../../services/missing-kyc-step.service';

@Injectable({
  providedIn: 'root',
})
export class CompletedInvestmentInfoVerificationService {
  constructor(
    private onboardingStateService: OnboardingStateService,
    private missingKycStepService: MissingKycStepService,
  ) {}

  close() {
    // this.onboardingStateService.setSection('LOCAL');
    // this.missingKycStepService.updateState({
    //   enabledCats: {
    //     general: false,
    //     global: false,
    //     local: true,
    //   },
    // });
    // this.onboardingStateService.nextTabNoIndex();
  }
}

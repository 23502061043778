<div *ngIf="kyc.state | async as state" class="flex-col flex">
  <h3 class="fs-18 fw-600 mb-0 dark-01 text-center">Verify phone number</h3>
  <p class="fs-14 mt-0 grey-02 mb-3 text-center">
    Enter the verification code that was sent to your<br />
    phone number
    <!-- {{
      state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT
        ? "Bank Account"
        : "BVN"
    }} -->
    <span *ngIf="state.profile.phone" class="primary-color bg-transparent w-2/6 border-0">{{ state.profile.phone | phoneNumber }} </span>
  </p>
  <div (click)="showPhoneNumber = !showPhoneNumber" *ngIf="!showPhoneNumber; else showPhoneControl" class="flex fs-12 primary-color text-center mb-3 justify-center items-center cursor-pointer">
    <mat-icon class="icon-display" style="height: 12px" svgIcon="edit-icon"></mat-icon>
    <span class="py-1">Edit phone number</span>
  </div>

  <ng-template #showPhoneControl>
    <form [formGroup]="editPhoneForm">
      <div class="my-10 flex flex-col">
        <phone-country-selector (onInput)="receiveNumber($event)"></phone-country-selector>
        <mat-form-field appearance="outline" class="w-full mt-2 fs-12">
          <mat-label>Enter Date of Birth (mm/dd/yyyy)</mat-label>
          <input (click)="picker.open()" [matDatepicker]="picker" [max]="maxDate" formControlName="dob" matInput />
          <mat-datepicker-toggle [for]="picker" matSuffix>
            <mat-icon matDatepickerToggleIcon>today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button (click)="changePhoneNumber()" [disabled]="!editPhoneForm.valid" class="cbtn-plain mt-4 fs-12">Submit</button>
      </div>
    </form>
  </ng-template>

  <div class="input-column mb-3">
    <ng-otp-input (onInputChange)="otpChange($event)" [config]="otpConfig"></ng-otp-input>
  </div>
  <div class="form-group text-center">
    <label *ngIf="!!(countDown$ | async)" class="text-center fs-14 primary-color"> {{ countDown$ | async }} Secs To Resend OTP </label>

    <button (click)="changePhoneNumber(); this.startOTPDelayTimer()" *ngIf="showPhoneNumber" [disabled]="!editPhoneForm.valid && !!(countDown$ | async)" class="cbtn-plain w-full my-3" type="submit">Resend Code</button>
    <button (click)="kyc.sendPhoneOTP(); this.startOTPDelayTimer()" *ngIf="!showPhoneNumber" [disabled]="!!(countDown$ | async)" class="cbtn-plain w-full my-3" type="submit">Resend Code</button>
  </div>
  <div class="form-group my-6">
    <mat-progress-bar *ngIf="state.loading" mode="indeterminate"></mat-progress-bar>
    <button (click)="submit()" class="cbtn cbtn-md cbtn-primary w-full my-3" type="submit">Verify Code</button>
  </div>
</div>

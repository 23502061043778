import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilApiResponse } from '../../core/http/api.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DownloadState {
  loading: boolean;
  data: Blob | undefined;
  error?: string;
}

const initialState: DownloadState = {
  loading: false,
  data: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class DownloadStateService {
  state = new BehaviorSubject<DownloadState>(initialState);

  constructor(
    public readonly http: HttpClient,
    private _snackBar: MatSnackBar,
  ) {}

  public async getPdfFileAction(filename: string) {
    const { w8BenFileUrl } = environment;

    return this.http.get(w8BenFileUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
      }),
      responseType: 'blob',
    });
  }

  public updateState(state: Partial<DownloadState>) {
    this.state.next({ ...this.state.getValue(), ...state });
  }

  private onFileDownloaded(value: Blob) {
    this.state.next({
      data: value,
      loading: false,
    });
  }

  private onFileDownloadError(res: UtilApiResponse<string>) {
    this.state.next({
      ...this.state.getValue(),
      error: res.message,
      loading: false,
    });

    this._snackBar.open(res.message, `Close`);
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ShellComponent } from './shell/shell.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { NgOtpInputModule } from 'ng-otp-input';
import { BankVerificationComponent } from './bank-verification/bank-verification.component';
import { BvnVerificationComponent } from './bvn-verification/bvn-verification.component';
import { VerificationChoiceComponent } from './verification-choice/verification-choice.component';
import { DobVerificationComponent } from './dob-verification/dob-verification.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { EmploymentInfoVerificationComponent } from './employment-info-verification/employment-info-verification.component';
import { InvestmentInfoVerificationComponent } from './investment-info-verification/investment-info-verification.component';
import { NextKinInfoComponent } from './next-kin-info/next-kin-info.component';
import { PersonalInfoVerificationComponent } from './personal-info-verification/personal-info-verification.component';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { TradingInfoComponent } from './trading-info/trading-info.component';
import { SharedModule } from '../shared/shared.module';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { BvnBankVerificationComponent } from './bvn-verification/bvn-bank-verification/bvn-bank-verification.component';
import { MatButtonModule } from '@angular/material/button';
import { CompletedInvestmentInfoVerificationComponent } from './investment-info-verification/completed-investment-info-verification/completed-investment-info-verification.component';

@NgModule({
  declarations: [
    ShellComponent,
    BankVerificationComponent,
    BvnVerificationComponent,
    VerificationChoiceComponent,
    DobVerificationComponent,
    DocumentUploadComponent,
    EmploymentInfoVerificationComponent,
    InvestmentInfoVerificationComponent,
    NextKinInfoComponent,
    PersonalInfoVerificationComponent,
    PhoneVerificationComponent,
    TradingInfoComponent,
    EmailVerificationComponent,
    BvnBankVerificationComponent,
    CompletedInvestmentInfoVerificationComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatTabsModule,
    RouterModule,
    MatIconModule,
    MatSelectModule,
    SharedModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgOtpInputModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatBottomSheetModule,
    MatButtonModule,
  ],
  providers: [
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class OnboardingModule {}

import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OnboardingStateService } from '../services/onboarding-state.service';

@Component({
  selector: 'email-verification',
  templateUrl: './email-verification.component.html',
})
export class EmailVerificationComponent implements OnInit, AfterContentInit {
  otpControl: FormControl = new FormControl('', Validators.required);
  otpConfig = {
    length: 6,
    isPasswordInput: true,
    containerClass: 'otpContainer',
    inputClass: 'otp-input',
    allowNumbersOnly: true,
  };

  constructor(public kycState: OnboardingStateService) {}

  ngAfterContentInit(): void {
    this.kycState.sendEmailOtp();
  }

  ngOnInit(): void {
    this.kycState.setSection('GENERAL');
  }

  otpChange(otp: string) {
    if (otp.length == 6) {
      this.otpControl.patchValue(otp);
    } else {
      this.otpControl.patchValue('');
    }
  }
}

import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements AfterViewInit {
  isPdf = false;
  fileName: string;
  resourceUrl: SafeResourceUrl;
  imageSrc: string = '';

  constructor(
    private dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private previewData: File,
    private _sanitizer: DomSanitizer
  ) {
    this.isPdf = previewData.type.includes('pdf');

    this.fileName = previewData?.name;

    const reader = new FileReader();
    reader.readAsDataURL(previewData);

    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };

    this.resourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(previewData)
    );
  }

  ngAfterViewInit(): void {
    // let image: any = document.getElementById('image-preview');
    // image.src = URL.createObjectURL(this.previewData)
    // image.onload = function () {
    //   URL.revokeObjectURL(image.src)
    // }
  }

  close() {
    this.dialogRef.close();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseService } from './http/base-service.service';
import { AuthStateService } from './auth/auth.state.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http/auth-interceptor.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    BaseService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthStateService
  ]
})
export class CoreModule { }

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CloseAppService {

  constructor() { }

  closeApp(location: string) {
    console.log(location)
    window.parent.postMessage('closeSDK', '*');
  }
}

<div class="flex flex-col">
  <h3 class="fs-18 fw-600 mb-0 dark-01">Required Trading Information</h3>
  <p class="fs-14 mt-0 grey-02 mb-5">
    Enter your CHN Number. If you do not have this,<br />
    please leave the field empty.
  </p>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>CHN Number</mat-label>
    <input [formControl]="chnControl" matInput type="text" minlength="10" />
  </mat-form-field>
  <div class="flex flex-col text-center">
    <mat-progress-bar
      *ngIf="(kyc.state | async)?.loading"
      mode="indeterminate"
    ></mat-progress-bar>
    <button
      [disabled]="formValidity"
      (click)="kyc.createCscs({ cscsCHN: chnControl.value })"
      class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2"
    >
      Start Trading Locally
    </button>
    <button
      style="color: #4e5d6d"
      (click)="closeApp()"
      class="cbtn cbtn-plain fs-14 py-3 px-20 mt-2"
    >
      I do not have this
    </button>
  </div>
</div>


import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
} from 'src/app/core/http/api.interface';
import { KYCUtilService } from './kyc-util.service';
import {
  Bank,
  IVerifyBank,
  NameLookupResponse,
  NubanInfo,
} from './onboarding.interface';
import { OnboardingService } from './onboarding.service';

export interface BankState {
  banks: Bank[];
  loading: boolean;
  error?: string;
  accountInfo: NubanInfo;
  bankInfo: IVerifyBank;
  customerInfo: NameLookupResponse;
}

const initalState: BankState = {
  loading: false,
  banks: [],
  accountInfo: {} as NubanInfo,
  bankInfo: {} as IVerifyBank,
  customerInfo: {} as NameLookupResponse,
};

@Injectable({ providedIn: 'root' })
export class BankStateService {
  state = new BehaviorSubject<BankState>(initalState);

  constructor(
    private util: KYCUtilService,
    private onboarding: OnboardingService,
    private _snackBar: MatSnackBar
  ) {}

  list() {
    this.loading();
    this.util.banks().subscribe({
      next: this.onBankLoaded.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  reset() {
    this.state.next(initalState);
  }

  public getBankInfo(payload: { bankId: number; accountNumber: string }) {
    this.loading();
    this.onboarding.bankAccountInfo(payload).subscribe(
      (response) => this.setBankName(response, payload),
      (error) => this.onBankError(error)
    );
  }

  public getAccountName(payload: { bankId: number; accountNumber: string }) {
    this.loading();
    this.onboarding.accountNameInfo(payload).subscribe(
      (response) => {
        if(response.data.valid){
          this.setAcctName(response, payload)
          return
        }
        this._snackBar.open('The Account Number/Bank you have selected does not match', `Close`);
        this.loading()
      },
      (error) => this.onBankError(error)
    );
  }

  setAcctName(
    { data }: ReqSuccessResponse<NameLookupResponse>,
    bankPayload: { bankId: number; accountNumber: string }
  ) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      customerInfo: data,
      bankInfo: {
        accountNumber: bankPayload.accountNumber,
        bankId: bankPayload.bankId,
        bankAcctName: data.accountName
      },
    });
  }

  private setBankName(
    { data }: ReqSuccessResponse<NubanInfo>,
    bankPayload: { bankId: number; accountNumber: string }
  ) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      accountInfo: data,
      bankInfo: {
        accountNumber: bankPayload.accountNumber,
        bankId: bankPayload.bankId,
        bankAcctName: data.accountName
      },
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onBankLoaded({ data }: ReqSuccessResponse<Bank[]>) {
    this.state.next({
      ...this.state.getValue(),
      banks: data,
      loading: false,
    });
  }

  private onBankError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });
    this._snackBar.open(error, `Close`);
  }
}

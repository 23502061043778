import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, skipWhile } from 'rxjs/operators';
import { OnboardingStateService } from 'src/app/onboarding/services/onboarding-state.service';
import { KYCProfile } from 'src/app/onboarding/services/onboarding.interface';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../http/api.interface';
import { AuthService } from './auth.service';

export interface AuthProfileState {
  loading: boolean;
  error?: string;
  profile: KYCProfile;
}

const initialState: AuthProfileState = {
  loading: true,
  profile: {} as KYCProfile,
};

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  state = new BehaviorSubject<AuthProfileState>(initialState);

  constructor(private auth: AuthService, private kycState: OnboardingStateService) {}

  retrieveUserProfile() {
    this.loading();
    this.auth
      .getUserByToken()
      .pipe(first())
      .subscribe({
        next: this.onProfileLoaded.bind(this),
        error: this.onError.bind(this),
      });
  }

  private onProfileLoaded({ data }: ReqSuccessResponse<KYCProfile>) {
    this.state.next({
      ...this.state.getValue(),
      profile: data,
      loading: false,
    });
    this.kycState.onUserKycLoaded(data)
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';
import { KYCProfile, VerificationChoiceEnum } from '../services/onboarding.interface';
import { NigeriaPreset } from './nigeria.states';
import { MissingKycStepService } from '../services/missing-kyc-step.service';

@Component({
  selector: 'personal-info-verification',
  templateUrl: './personal-info-verification.component.html',
})
export class PersonalInfoVerificationComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  editAddress: boolean = false;
  addressForm: FormGroup;
  states: string[] = [];
  lgas: string[] = [];
  stateEnum = Object.create(NigeriaPreset);
  choiceEnum = VerificationChoiceEnum;

  constructor(
    public datePipe: DatePipe,
    public kyc: OnboardingStateService,
    public missingKycStepService: MissingKycStepService,
  ) {
    this.addressForm = new FormGroup({
      state: new FormControl('', Validators.required),
      lg: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      postalCode: new FormControl('23401'),
      city: new FormControl(''),
    });
  }

  get formValidity(): boolean {
    return this.addressForm.invalid || this.kyc.state.value.loading;
  }

  ngOnInit(): void {
    this.kyc.setSection('GENERAL');
    this.kyc.getProfile();
    this.getLgas();
    this.getStates();
  }

  submit(data: Partial<KYCProfile>) {
    let { firstName, lastName, state, address, gender, postalCode, lg } = data;

    this.kyc.updateKyc(
      {
        firstName,
        lastName,
        state,
        address,
        gender,
        postalCode: postalCode ?? '23401',
        city: data.lg,
        lg: `${lg}--${state}`,
      },
      true,
    );
  }

  setForm(data: Partial<KYCProfile>) {
    this.addressForm.patchValue({
      state: data.state ?? '',
      lg: data.lg ?? '',
      address: data.address ?? '',
      postalCode: data.postalCode ?? '23401',
      city: data.lg ?? '',
    });
    this.editAddress = true;
  }

  setCity(lg: any) {
    this.addressForm.patchValue({
      city: lg.value,
    });
  }

  getStates() {
    let data = [];
    for (const [key] of Object.entries(NigeriaPreset)) {
      data.push(key);
    }
    this.states = data;
  }

  getLgas() {
    let state: string = this.addressForm.get('state')?.value;
    if (state) {
      this.lgas = [...this.stateEnum[state].lgas];
    }
  }
}

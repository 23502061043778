import { ITab } from './onboarding.interface';

export let tabs: ITab[] = [
  {
    kyc: 'EMAIL_VERIFICATION',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 0,
      genglobal: 0,
      genlocal: 0,
      all: 0,
    },
  },
  {
    kyc: 'PERSONAL_DETAILS',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 4,
      genglobal: 4,
      genlocal: 4,
      all: 4,
    },
  },
  {
    kyc: 'DOB',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 3,
      genglobal: 3,
      genlocal: 3,
      all: 3,
    },
  },
  {
    kyc: 'BVN',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 1,
      genglobal: 1,
      genlocal: 1,
      all: 1,
    },
  },
  {
    kyc: 'PHONE',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 5,
      genglobal: 5,
      genlocal: 5,
      all: 5,
    },
  },
  {
    kyc: 'BANK',
    completed: false,
    tabIndex: {
      global: null,
      local: null,
      general: 1,
      genglobal: 1,
      genlocal: 1,
      all: 1,
    },
  },
  {
    kyc: 'EMPLOYMENT_STATUS',
    completed: false,
    tabIndex: {
      global: 2,
      local: null,
      general: null,
      genglobal: 7,
      genlocal: null,
      all: 7,
    },
  },
  {
    kyc: 'DOC_UPLOAD',
    completed: false,
    tabIndex: {
      global: 1,
      local: null,
      general: null,
      genglobal: 6,
      genlocal: null,
      all: 6,
    },
  },
  {
    kyc: 'COMPLIANCE',
    completed: false,
    tabIndex: {
      global: null,
      local: 1,
      general: null,
      genglobal: null,
      genlocal: 6,
      all: 9,
    },
  },
  {
    kyc: 'TRADING_INFO',
    completed: false,
    tabIndex: {
      global: null,
      local: 2,
      general: null,
      genglobal: null,
      genlocal: 7,
      all: 10,
    },
  },
];

export const kycModes = [
  {
    type: 'ALL',
    name: '',
    categories: {
      global: true,
      local: true,
      general: true,
    },
  },
  {
    type: 'GENERAL',
    name: 'GENERAL',
    categories: {
      global: false,
      local: false,
      general: true,
    },
  },
  {
    type: 'LOCAL',
    name: 'LOCAL',
    categories: {
      global: false,
      local: true,
      general: false,
    },
  },
  {
    type: 'GLOBAL',
    name: 'GLOBAL',
    categories: {
      global: true,
      local: false,
      general: false,
    },
  },
  {
    type: 'GENGLOBAL',
    name: 'General & Global',
    categories: {
      global: true,
      local: false,
      general: true,
    },
  },
  {
    type: 'GENLOCAL',
    name: 'General & Local',
    categories: {
      global: false,
      local: true,
      general: true,
    },
  },
];

<div class="flex flex-col">
  <div class="flex w-full justify-center my-5">
    <div class="svg-wrapper">
      <div class="self-center">
        <svg fill="none" height="49" viewBox="0 0 49 49" width="49" xmlns="http://www.w3.org/2000/svg">
          <mask height="49" id="mask0_473_19" maskUnits="userSpaceOnUse" style="mask-type: alpha" width="49" x="0" y="0">
            <rect fill="#D9D9D9" height="48" width="48" x="0.51001" y="0.530273" />
          </mask>
          <g mask="url(#mask0_473_19)">
            <path
              d="M21.21 34.5303L32.51 23.2303L29.61 20.3303L21.16 28.7803L16.96 24.5803L14.11 27.4303L21.21 34.5303ZM13.51 40.5303C10.4767 40.5303 7.88501 39.4803 5.73501 37.3803C3.58501 35.2803 2.51001 32.7136 2.51001 29.6803C2.51001 27.0803 3.29334 24.7636 4.86001 22.7303C6.42668 20.6969 8.47668 19.3969 11.01 18.8303C11.8433 15.7636 13.51 13.2803 16.01 11.3803C18.51 9.48027 21.3433 8.53027 24.51 8.53027C28.41 8.53027 31.7183 9.88861 34.435 12.6053C37.1517 15.3219 38.51 18.6303 38.51 22.5303C40.81 22.7969 42.7183 23.7886 44.235 25.5053C45.7517 27.2219 46.51 29.2303 46.51 31.5303C46.51 34.0303 45.635 36.1553 43.885 37.9053C42.135 39.6553 40.01 40.5303 37.51 40.5303H13.51Z"
              fill="white" />
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="flex justify-center my-6">
    <p class="fs-16 text-center c-case">
      {{ data.title }}
    </p>
  </div>
  <button (click)="close()" class="cbtn cbtn-primary fs-16 py-3 c-case">
    {{ data.cta }}
  </button>
</div>

export interface option {
  label: string;
  value: any;
}

export const termOptions: option[] = [
  {
    label: 'Protection',
    value: 'LONG_TERM',
  },
  {
    label: 'Growth',
    value: 'FREQUENT',
  },
  {
    label: 'Income',
    value: 'ACTIVE_DAILY',
  },
];

export const expOptions: option[] = [
  {
    label: '0-2 Years',
    value: 'YRS_1_2',
  },
  {
    label: '3 - 5 Years',
    value: 'YRS_3_5',
  },
  {
    label: '5 Years +',
    value: 'YRS_5_10',
  },
  {
    label: '10 Years',
    value: 'YRS_10_',
  },
];

export const incomeOptions: option[] = [
  {
    label: '500k - 1m',
    value: 1000000,
  },
  {
    label: '<5Million',
    value: 500000,
  },
  {
    label: '5 - 50m',
    value: 50000000,
  },
];

export const liquidNwOptions: option[] = [
  {
    label: '<5Million',
    value: 5000000,
  },
  {
    label: '5 - 50m',
    value: 1000000,
  },
  {
    label: '50Million +',
    value: 50000000,
  },
];

export const riskOptions: option[] = [
  {
    label: 'Conservative',
    value: 'LOW',
  },
  {
    label: 'Neutral',
    value: 'MODERATE',
  },
  {
    label: 'Risk Seeking',
    value: 'SPECULATION',
  },
];

export const fundingSourceOptions: option[] = [
  { label: 'Employment', value: 'Employment' },
  { label: 'Business Proceeds', value: 'Business Proceeds' },
  { label: 'Savings', value: 'Savings' },
  { label: 'Inheritance', value: 'Inheritance' },
  { label: 'Retirement Funds', value: 'Retirement Funds' },
];

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PhoneNumberValidator } from '../../PhoneNumber.validator';

export interface ICountry {
  alpha2Code: string;
  alpha3Code: string;
  name: string;
  flag: string;
  callingCode: string;
}

export interface ICountryExtra extends ICountry {
  phoneNumber: string;
}


@Component({
  selector: 'phone-country-selector',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})

export class PhoneVerificationComponent {
  phoneNumberControl: FormControl;

  selectedCountry: ICountry = {
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    flag: '../assets/icons/nigeria-flag.svg',
    callingCode: '234',
  };

  @Output() onInput = new EventEmitter<ICountryExtra | null>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'country-flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.selectedCountry.flag
      )
    );

    this.matIconRegistry.addSvgIcon(
      'expand-more',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/expand-more.svg'
      )
    );

    this.phoneNumberControl = new FormControl('', [
      Validators.required,
      PhoneNumberValidator(this.selectedCountry.alpha2Code),
    ]);

    this.phoneNumberControl.valueChanges.subscribe((event: string) => {
      if (!this.phoneNumberControl.valid) {
        return;
      }

      this.onInput.emit({ ...this.selectedCountry, phoneNumber: event });
    });
  }
  selectCountry() {
    throw new Error('Not yet implemented');
  }
}

import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, throwError } from 'rxjs';
import { CSCSS, FileUpload, IAcceptedDocuments, IEditPhone, KYCProfile, UploadType, VerificationChoiceEnum, VerificationTypes } from './onboarding.interface';
import { OnboardingService } from './onboarding.service';
import { BankStateService } from './bank-state.service';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from 'src/app/core/http/api.interface';
import { UploadStateService } from './upload-state.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { th } from '@faker-js/faker';

export interface KycState {
  loading: boolean;
  error?: boolean;
  selectedTab: number;
  profile: KYCProfile;
  ADDRESS_PROOF: boolean;
  PHOTO_ID: boolean;
  section: string;
  acceptedDocuments: IAcceptedDocuments[];
  verificationChoice: VerificationChoiceEnum;
  bvn: string;
  maskedPhoneNumber: string;
  verificationMode: string;
}

const initialState: KycState = {
  loading: false,
  profile: {} as KYCProfile,
  selectedTab: 0,
  ADDRESS_PROOF: false,
  PHOTO_ID: false,
  verificationMode: VerificationTypes.ALL,
  section: 'GENERAL',
  acceptedDocuments: [],
  verificationChoice: VerificationChoiceEnum.PAYOUT_ACCOUNT,
  bvn: '',
  maskedPhoneNumber: '23470xxxx4936',
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingStateService {
  state = new BehaviorSubject<KycState>(initialState);

  constructor(
    private kyc: OnboardingService,
    private upload: UploadStateService,
    private bankState: BankStateService,
    private _snackBar: MatSnackBar,
    private modal: ModalService,
    private gtm: GoogleTagManagerService,
  ) {}

  updateKyc(UserKyc: Partial<KYCProfile>, nextTab: boolean = false) {
    this.loading();
    this.kyc.updateKyc(UserKyc).subscribe(
      (response: ReqSuccessResponse<KYCProfile>) => {
        this.updateKycState(response.data, nextTab);
      },
      (error: ChakaAPIError) => {
        this.onError(error);
      },
    );
  }

  editPhoneNumber(data: IEditPhone) {
    this.loading();
    this.kyc.changePhoneNumber(data).subscribe(
      Response => this.onUserKycLoaded.bind(this),
      error => this.onError(error),
    );
  }

  createCscs(payload: CSCSS) {
    this.loading();
    this.kyc.createCscss(payload).subscribe({
      next: this.onCscsCreate.bind(this),
      error: this.onError.bind(this),
    });
  }

  onCscsCreate({ message }: ReqSuccessResponse<string>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
    });
    this.gtm.pushTag({
      event: 'LOCAL_KYC_COMPLETE',
    });
    this._snackBar.open(message);
    this.modal.openOnboardModal({
      title: 'You have now completed your verification',
      cta: 'Explore Local Stocks',
    });
  }

  public updateVerificationMode(verificationMode: string) {
    this.state.next({
      ...this.state.getValue(),
      verificationMode,
    });
  }

  public sendEmailOtp() {
    this.kyc.sendEmailOtp().subscribe(
      response => this._snackBar.open(response.message, 'close'),
      error => this.onError(error),
    );
  }

  validateOtp(otp: string) {
    this.loading();
    this.kyc.validateEmailOtp(otp).subscribe(
      response => {
        this.state.next({
          ...this.state.getValue(),
          loading: false,
        });
        this.nextTab(1);
      },
      error => {
        this.onError(error);
      },
    );
  }

  nextTabNoIndex() {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      selectedTab: this.state.getValue().selectedTab + 1,
    });
  }

  getProfile() {
    this.loading();
    this.kyc.profile().subscribe(
      (response: ReqSuccessResponse<KYCProfile>) => {
        this.onUserKycLoaded(response.data);
      },
      (error: ChakaAPIError) => {
        this.onError(error);
      },
    );
  }

  public setSection(section: string) {
    this.state.next({
      ...this.state.getValue(),
      section,
    });
  }

  public nextTab(tabIndex: number) {
    this.state.next({
      ...this.state.getValue(),
      selectedTab: tabIndex,
    });
  }

  submitNin(ninPayload: { nin: string }, payload: Map<UploadType, FileUpload>) {
    this.loading();

    this.kyc.submitNin(ninPayload).subscribe({
      next: () => {
        this.uploadDocument(payload, false);
      },
      error: error => this.onError(error),
    });
  }

  public setVerificationChoice(verificationChoice: VerificationChoiceEnum) {
    this.state.next({
      ...this.state.getValue(),
      verificationChoice,
    });
  }

  uploadDocument(payload: Map<UploadType, FileUpload>, showLoading = true) {
    showLoading && this.loading();

    this.kyc
      .uploadDocument(payload.get(UploadType.IDENTITY))
      .pipe(
        catchError(error => {
          this.onError(error);

          return throwError(error);
        }),
        switchMap(() => {
          this.upload.documentUpload(UploadType.PHOTO_ID);

          return this.kyc.uploadDocument(payload.get(UploadType.PROOF_OF_ADDRESS));
        }),
      )
      .subscribe({
        next: () => {
          this.upload.documentUpload(UploadType.ADDRESS_PROOF);
          this.nextTabNoIndex();
        },
      });
  }

  public onUserKycLoaded(data: KYCProfile) {
    const { profile } = this.state.getValue();
    this.state.next({
      ...this.state.getValue(),
      profile: { ...profile, ...data },
      loading: false,
    });
  }

  onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error: true,
      loading: false,
    });
    this._snackBar.open(error, `Close`);
  }

  sendPhoneOTP() {
    this.loading();
    this.kyc.sendOTP().subscribe({
      next: this.onOTPSuccess.bind(this),
      error: this.onError.bind(this),
    });
  }

  confirmPhoneOTP(OTPCode: string) {
    this.loading();
    this.kyc.confirmOTP(OTPCode).subscribe({
      next: this.onOTPValidate.bind(this),
      error: this.onError.bind(this),
    });
  }

  getSupportedDocuments(callback: () => void) {
    const { acceptedDocuments } = this.state.getValue();

    if (acceptedDocuments.length > 0) {
      callback();
      return;
    }

    this.loading();

    this.kyc.getSupportDocuments().subscribe({
      next: resp => {
        this.state.next({
          ...this.state.getValue(),
          acceptedDocuments: resp.data,
          loading: false,
        });

        callback();
      },
      error: this.onError.bind(this),
    });
  }

  public updateVerificationChoice(verificationChoice: VerificationChoiceEnum): void {
    this.state.next({
      ...this.state.getValue(),
      verificationChoice,
    });
  }

  public updateStateBvn(bvn: string): void {
    this.state.next({
      ...this.state.getValue(),
      bvn,
    });
    this.nextTabNoIndex();
  }

  submitDOB(dob: string) {
    this.loading();
    const { verificationChoice, bvn } = this.state.getValue();
    switch (verificationChoice) {
      case VerificationChoiceEnum.PAYOUT_ACCOUNT:
        {
          if (bvn.length > 0) {
            this.followBvnRoute(bvn, dob);
          } else {
            this.followPayoutRoute(dob);
          }
        }
        break;
      case VerificationChoiceEnum.BVN:
        this.followBvnRoute(bvn, dob);
        break;
      default:
        break;
    }
  }

  public updateKycState(data: Partial<KYCProfile>, nextTab: boolean) {
    const { profile } = this.state.getValue();
    if (nextTab) {
      this.state.next({
        ...this.state.getValue(),
        profile: { ...profile, ...data },
        loading: false,
      });
      this.nextTabNoIndex();
    } else {
      this.state.next({
        ...this.state.getValue(),
        profile: { ...profile, ...data },
        loading: false,
      });
    }
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      error: false,
    });
  }

  private onOTPValidate({ message }: ReqSuccessResponse<string>) {
    const { phoneNumber: phone } = this.bankState.state.getValue().accountInfo;
    const kycMode = this.state.getValue().verificationMode;
    if (kycMode == VerificationTypes.GENERAL) {
      this.updateKyc({ phone }, false);
      this.modal.openOnboardModal({
        title: 'You’re Almost There!',
        cta: 'Submit your global verification',
      });
      return;
    }
    this.updateKyc({ phone }, true);
  }

  private onOTPSuccess({ data }: ReqSuccessResponse<string>) {
    const messageArray = data.split(' ');
    const maskedPhoneNumber = messageArray[messageArray.length - 1];
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      maskedPhoneNumber,
    });
    this._snackBar.open(data, `Close`);
  }

  private followPayoutRoute(dob: string) {
    const { bankInfo } = this.bankState.state.getValue();
    const { accountNumber, bankId } = bankInfo;
    const payload = { dob, accountNumber, bankId };
    this.kyc.verifyBankAcct(payload).subscribe(
      response => this.nextTabNoIndex(),
      error => this.onError(error),
    );
  }

  private followBvnRoute(bvn: string, dob: string) {
    this.loading();
    const { bankId, accountNumber, bankAcctName } = this.bankState.state.getValue().bankInfo;
    const payload = {
      bankId: `${bankId}`,
      bankAcctNo: accountNumber,
      bankAcctName,
    };
    this.kyc.verifyBVN({ bvn, dob }).subscribe(
      response => {
        this.updateKyc(payload, true);
      },
      error => this.onError(error),
    );
  }
}

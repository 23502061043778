import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmploymentStatus } from 'src/app/core/auth/auth.interface';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';
import { employmentOptionsArray, employmentPositionArray, employmentTypesArray, SelectOption } from './employment-details.data';
import { Question, YES_NO } from './constants';

@Component({
  selector: 'employment-info-verification',
  templateUrl: './employment-info-verification.component.html',
  styleUrls: ['./employment-info-verification.component.scss'],
})
export class EmploymentInfoVerificationComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  public declare personalInfoForm: FormGroup;
  public questions: Question[] = [
    {
      question: 'Do any of the following apply to you or a member of your immediate family?',
      options: [],
      controlName: 'extraInvestmentInfo',
      children: [
        {
          question: 'Affiliated or worked with a US registered broker dealer or FINRA.',
          options: [...YES_NO],
          controlName: 'isAffiliatedToBrokerOrFinra',
        },
        {
          question: 'Senior executive at or a 10% or greater shareholder of a publicly traded company.',
          options: [...YES_NO],
          controlName: 'isSeniorExecutive',
        },
        {
          question: 'I am a senior political figure.',
          options: [...YES_NO],
          controlName: 'isPoliticalFigure',
        },
        {
          question: 'I am a family member or relative of a senior political figure.',
          options: [...YES_NO],
          controlName: 'isFamilyMemberOrRelativeOfPoliticalFigure',
        },
      ],
    },
  ];
  selectedEmployment: EmploymentStatus | undefined;
  employmentEnum = EmploymentStatus;
  employmentTypes: SelectOption[];
  employmentPositions = employmentPositionArray;
  employmentOptions = employmentOptionsArray;
  companyInfo: FormGroup;
  employmentForm: FormGroup;
  pepNames: FormControl = new FormControl('');

  constructor(public kyc: OnboardingStateService) {
    this.employmentTypes = employmentTypesArray.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    let {
      employmentStatus,
      employmentCompany,
      employmentPosition,
      employmentType,
      employerAddress,
      isFamilyMemberOrRelativeOfPoliticalFigure,
      isPoliticalFigure,
      isSeniorExecutive,
      noneApplyExtraInvestmentInfo,
      extraInvestmentInfo,
      isAffiliatedToBrokerOrFinra,
    } = this.kyc.state.getValue().profile;
    this.employmentForm = new FormGroup({
      employmentStatus: new FormControl(employmentStatus, Validators.required),
    });
    this.companyInfo = new FormGroup({
      employmentCompany: new FormControl(employmentCompany ?? ''),
      employmentPosition: new FormControl(employmentPosition ?? ''),
      employmentType: new FormControl(employmentType ?? ''),
      employerAddress: new FormControl(employerAddress ?? ''),
    });

    this.personalInfoForm = new FormGroup({
      extraInvestmentInfo: new FormControl(extraInvestmentInfo),
      isAffiliatedToBrokerOrFinra: new FormControl(isAffiliatedToBrokerOrFinra, Validators.required),
      isSeniorExecutive: new FormControl(isSeniorExecutive, Validators.required),
      isPoliticalFigure: new FormControl(isPoliticalFigure, Validators.required),
      isFamilyMemberOrRelativeOfPoliticalFigure: new FormControl(isFamilyMemberOrRelativeOfPoliticalFigure, Validators.required),
      noneApplyExtraInvestmentInfo: new FormControl(noneApplyExtraInvestmentInfo),
    });

    this.personalInfoForm.valueChanges.subscribe(value => {
      this.handleNoneApplyExtraInvestmentInfoChange(value);
    });
  }

  get getFormValidity(): boolean {
    return this.employmentForm.invalid || this.kyc.state.value.loading || this.companyInfo.invalid || this.personalInfoForm.invalid;
  }

  ngOnInit(): void {
    this.kyc.setSection('GLOBAL');
  }

  setStatus$(value: string, formControlName: string) {
    this.personalInfoForm.patchValue({
      [`${formControlName}`]: value,
    });
  }

  setStatus(status: boolean, item: string, empStatus?: EmploymentStatus) {
    switch (item) {
      case 'employment':
        this.selectedEmployment = empStatus;
        this.employmentForm.patchValue({
          employmentStatus: empStatus,
        });

        if (empStatus == EmploymentStatus.EMPLOYED || empStatus == EmploymentStatus.SELF_EMPLOYED) {
          this.companyInfo.get('employmentCompany')?.setValidators(Validators.required);
          this.companyInfo.get('employmentPosition')?.setValidators(Validators.required);
          this.companyInfo.get('employmentType')?.setValidators(Validators.required);
          this.companyInfo.get('employerAddress')?.setValidators(Validators.required);
          this.updateFormValidity();
        } else {
          this.companyInfo.reset();
          this.companyInfo.get('employmentCompany')?.setValidators(null);
          this.companyInfo.get('employmentPosition')?.setValidators(null);
          this.companyInfo.get('employmentType')?.setValidators(null);
          this.companyInfo.get('employerAddress')?.setValidators(null);
          this.updateFormValidity();
        }
        break;
      default:
        break;
    }
  }

  updateFormValidity() {
    this.companyInfo.get('employmentCompany')?.updateValueAndValidity();
    this.companyInfo.get('employmentPosition')?.updateValueAndValidity();
    this.companyInfo.get('employmentType')?.updateValueAndValidity();
    this.companyInfo.get('employerAddress')?.updateValueAndValidity();
  }

  submit() {
    this.mutateExtraInvestmentInfo();
    let payload = {
      ...this.companyInfo.value,
      ...this.employmentForm.value,
      ...this.personalInfoForm.value,
      pepNames: this.pepNames.value,
    };
    this.kyc.updateKyc(payload, true);
  }

  private handleNoneApplyExtraInvestmentInfoChange(value: any): void {
    if (value.noneApplyExtraInvestmentInfo) {
      this.personalInfoForm.patchValue(
        {
          isAffiliatedToBrokerOrFinra: YES_NO[1].label,
          isSeniorExecutive: YES_NO[1].label,
          isPoliticalFigure: YES_NO[1].label,
          isFamilyMemberOrRelativeOfPoliticalFigure: YES_NO[1].label,
        },
        { emitEvent: false },
      );
    }
  }

  private mutateExtraInvestmentInfo() {
    this.personalInfoForm.patchValue({
      extraInvestmentInfo: [
        {
          question: 'isAffiliatedToBrokerOrFinra',
          answer: this.personalInfoForm.value.isAffiliatedToBrokerOrFinra,
        },
        {
          question: 'isSeniorExecutive',
          answer: this.personalInfoForm.value.isAffiliatedToBrokerOrFinra,
        },
        {
          question: 'isPoliticalFigure',
          answer: this.personalInfoForm.value.isAffiliatedToBrokerOrFinra,
        },
        {
          question: 'isFamilyMemberOrRelativeOfPoliticalFigure',
          answer: this.personalInfoForm.value.isAffiliatedToBrokerOrFinra,
        },
      ],
    });
  }
}

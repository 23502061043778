import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { PhoneVerificationComponent } from './components/phone-verification/phone-verification.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LastXWords } from './pipes/last-x-words.pipe';
import { CompletedOnboardingComponent } from './components/completed-onboarding/completed-onboarding.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TruncateWordsPipe } from './pipes/truncate-words.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { CleanPhoneNumberDirective } from './directives/clean-phone-number.directive';
import { PrivayPolicyComponent } from '../privay-policy/privay-policy.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    DocumentPreviewComponent,
    PhoneVerificationComponent,
    LastXWords,
    TruncateWordsPipe,
    PrivayPolicyComponent,
    CleanPhoneNumberDirective,
    CompletedOnboardingComponent,
    PhoneNumberPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    RouterModule,
    MatFormFieldModule,
    CommonModule
  ],
  exports: [
    DocumentPreviewComponent,
    LastXWords,
    TruncateWordsPipe,
    PhoneVerificationComponent,
    CompletedOnboardingComponent,
    PhoneNumberPipe
  ]
})
export class SharedModule { }

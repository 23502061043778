<div class="flex flex-col min-w-full">
  <div class="flex-col w-full">
    <h4 class="fs-16 fw-600">Upload means of identification</h4>
    <p class="grey-02 fs-14 mb-6">Select the document you want to upload</p>

    <form [formGroup]="identityForm">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Select Document Type</mat-label>
        <mat-select (ngModelChange)="onDocTypeChange($event, uploadTypeEnum.IDENTITY)" formControlName="documentType" placeholder="Select Document Type">
          <mat-option *ngFor="let item of acceptedDocuments.get(uploadTypeEnum.IDENTITY)" [value]="item.name">
            {{ item.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isNinSlip" appearance="outline" class="w-full">
        <mat-label>NIN</mat-label>
        <input #input formControlName="nin" matInput maxlength="11" minlength="11" type="text" />
        <mat-hint align="end">{{ input.value?.length || 0 }}/11</mat-hint>
        <mat-error *ngIf="identityForm.get('nin')?.errors?.pattern">You can only input numbers.</mat-error>
      </mat-form-field>
    </form>

    <div class="flex flex-col">
      <div class="mb-3" id="front-identity-upload">
        <div [ngClass]="payload.get(uploadTypeEnum.IDENTITY)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card card-r-sm flex justify-between h-12">
          <input (change)="browseFile($event, uploadTypeEnum.IDENTITY)" [disabled]="identityForm.invalid" [id]="uploadTypeEnum.IDENTITY" accept="image/*, application/pdf" style="display: none" type="file" />
          <label [for]="uploadTypeEnum.IDENTITY" class="w-full text-center py-3 fs-12 flex items-center px-2 justify-center">
            {{ fileName.get(uploadTypeEnum.IDENTITY) || docDescription.get(uploadTypeEnum.IDENTITY) || 'Tap to select document' | truncateWords: 30 }}

            <button (click)="removeFile($event, uploadTypeEnum.IDENTITY)" *ngIf="payload.get(uploadTypeEnum.IDENTITY)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>
              <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>
            </button>
          </label>
        </div>
        <div *ngIf="payload.get(uploadTypeEnum.IDENTITY)?.file" class="text-center mt-1">
          <a (click)="previewDocument(uploadTypeEnum.IDENTITY)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>
        </div>
      </div>
      <div id="rear-identity-upload">
        <div [ngClass]="payload.get(uploadTypeEnum.IDENTITY_REAR)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card card-r-sm flex justify-between h-12">
          <input (change)="browseFile($event, uploadTypeEnum.IDENTITY_REAR)" [disabled]="identityForm.invalid" [id]="uploadTypeEnum.IDENTITY_REAR" accept="image/*, application/pdf" style="display: none" type="file" />
          <label [for]="uploadTypeEnum.IDENTITY_REAR" class="w-full text-center py-3 fs-12 flex items-center px-2 justify-center">
            {{ fileName.get(uploadTypeEnum.IDENTITY_REAR) || docDescription.get(uploadTypeEnum.IDENTITY_REAR) || 'Tap to select document' | truncateWords: 30 }}

            <button (click)="removeFile($event, uploadTypeEnum.IDENTITY_REAR)" *ngIf="payload.get(uploadTypeEnum.IDENTITY_REAR)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>
              <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>
            </button>
          </label>
        </div>
        <div *ngIf="payload.get(uploadTypeEnum.IDENTITY_REAR)?.file" class="text-center mt-1">
          <a (click)="previewDocument(uploadTypeEnum.IDENTITY_REAR)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>
        </div>
      </div>
    </div>
  </div>

  <hr class="my-4" />

  <div class="flex-col w-full">
    <h4 class="fs-16 fw-600">Upload proof of address</h4>
    <p class="grey-02 fs-14 mb-6">Select the document you want to upload</p>

    <form [formGroup]="proofOfAddressForm">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Select Document Type</mat-label>
        <mat-select (ngModelChange)="onDocTypeChange($event, uploadTypeEnum.PROOF_OF_ADDRESS)" formControlName="documentType" placeholder="Select Document Type">
          <mat-option *ngFor="let item of acceptedDocuments.get(uploadTypeEnum.PROOF_OF_ADDRESS)" [value]="item.name">
            {{ item.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div [ngClass]="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card mb-1 card-r-sm flex justify-between h-12">
      <input (change)="browseFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" [disabled]="proofOfAddressForm.invalid" [id]="uploadTypeEnum.PROOF_OF_ADDRESS" accept="image/*, application/pdf" style="display: none" type="file" />
      <label [for]="uploadTypeEnum.PROOF_OF_ADDRESS" class="w-full text-center py-3 flex fs-14 items-center px-2 justify-center">
        {{ fileName.get(uploadTypeEnum.PROOF_OF_ADDRESS) || docDescription.get(uploadTypeEnum.PROOF_OF_ADDRESS) || 'Tap to select document' | truncateWords: 30 }}

        <button (click)="removeFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>
          <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>
        </button>
      </label>
    </div>
    <div *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="text-center mt-1">
      <a (click)="previewDocument(uploadTypeEnum.PROOF_OF_ADDRESS)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>
    </div>
    <div class="flex flex-row">
      <!--      <div [ngClass]="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card mb-1 card-r-sm flex justify-between h-12">-->
      <!--        <input (change)="browseFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" [disabled]="proofOfAddressForm.invalid" [id]="uploadTypeEnum.PROOF_OF_ADDRESS" accept="image/*, application/pdf" style="display: none" type="file" />-->
      <!--        <label [for]="uploadTypeEnum.PROOF_OF_ADDRESS" class="w-full text-center py-3 flex fs-12 items-center px-2 justify-center">-->
      <!--          {{ fileName.get(uploadTypeEnum.PROOF_OF_ADDRESS) || docDescription.get(uploadTypeEnum.PROOF_OF_ADDRESS) || 'Tap to select document' | truncateWords: 30 }}-->

      <!--          <button (click)="removeFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>-->
      <!--            <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>-->
      <!--          </button>-->
      <!--        </label>-->
      <!--      </div>-->
      <!--      <div *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="text-center mt-1">-->
      <!--        <a (click)="previewDocument(uploadTypeEnum.PROOF_OF_ADDRESS)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>-->
      <!--      </div>-->
      <!--      <div id="front-proof-upload">-->
      <!--        <div [ngClass]="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card mb-1 card-r-sm flex justify-between h-12">-->
      <!--          <input (change)="browseFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" [disabled]="proofOfAddressForm.invalid" [id]="uploadTypeEnum.PROOF_OF_ADDRESS" accept="image/*, application/pdf" style="display: none" type="file" />-->
      <!--          <label [for]="uploadTypeEnum.PROOF_OF_ADDRESS" class="w-full text-center py-3 flex fs-12 items-center px-2 justify-center">-->
      <!--            {{ fileName.get(uploadTypeEnum.PROOF_OF_ADDRESS) || docDescription.get(uploadTypeEnum.PROOF_OF_ADDRESS) || 'Tap to select document' | truncateWords: 30 }}-->

      <!--            <button (click)="removeFile($event, uploadTypeEnum.PROOF_OF_ADDRESS)" *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>-->
      <!--              <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>-->
      <!--            </button>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--        <div *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS)?.file" class="text-center mt-1">-->
      <!--          <a (click)="previewDocument(uploadTypeEnum.PROOF_OF_ADDRESS)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="ml-2" id="rear-proof-upload">-->
      <!--        <div [ngClass]="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS_REAR)?.file ? 'bg-has-item' : 'bg-no-item'" class="custom-upload-card card mb-1 card-r-sm flex justify-between h-12">-->
      <!--          <input (change)="browseFile($event, uploadTypeEnum.PROOF_OF_ADDRESS_REAR)" [disabled]="proofOfAddressForm.invalid" [id]="uploadTypeEnum.PROOF_OF_ADDRESS_REAR" accept="image/*, application/pdf" style="display: none" type="file" />-->
      <!--          <label [for]="uploadTypeEnum.PROOF_OF_ADDRESS_REAR" class="w-full text-center py-3 flex fs-12 items-center px-2 justify-center">-->
      <!--            {{ fileName.get(uploadTypeEnum.PROOF_OF_ADDRESS_REAR) || docDescription.get(uploadTypeEnum.PROOF_OF_ADDRESS_REAR) || 'Tap to select document' | truncateWords: 30 }}-->

      <!--            <button (click)="removeFile($event, uploadTypeEnum.PROOF_OF_ADDRESS_REAR)" *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS_REAR)?.file" class="upload-button pull-right flex items-center mx-2" mat-icon-button>-->
      <!--              <mat-icon class="icon-display" svgIcon="upload-cancel"></mat-icon>-->
      <!--            </button>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--        <div *ngIf="payload.get(uploadTypeEnum.PROOF_OF_ADDRESS_REAR)?.file" class="text-center mt-1">-->
      <!--          <a (click)="previewDocument(uploadTypeEnum.PROOF_OF_ADDRESS_REAR)" class="text-center preview-link" href="javascript:" mat-flat-button>Preview</a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <span class="small grey-02 text-muted">Max file size (3Mb)</span>
    <span class="small grey-02 text-muted">Images and PDFs</span>
  </div>

  <div class="flex flex-col my-4">
    <mat-progress-bar *ngIf="(kyc.state | async)?.loading" mode="indeterminate"></mat-progress-bar>

    <button (click)="uploadDocuments()" class="cbtn cbtn-primary py-3 px-20 mt-4">Upload Document</button>
    <button (click)="kyc.nextTab(7)" class="cbtn cbtn-plain fs-14 py-3 px-20 mt-2" style="color: #4e5d6d">Skip</button>
  </div>
</div>

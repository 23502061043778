import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ModalService } from 'src/app/shared/services/modal.service';
import { OnboardingStateService } from '../services/onboarding-state.service';

@Component({
  selector: 'trading-info',
  templateUrl: './trading-info.component.html',
})
export class TradingInfoComponent implements OnInit {
  chnControl: FormControl;

  constructor(
    public kyc: OnboardingStateService,
    private modal: ModalService,
    private gtm: GoogleTagManagerService,
  ) {
    this.chnControl = new FormControl('', [Validators.required, Validators.minLength(10)]);
  }

  get formValidity(): boolean {
    return this.chnControl.invalid || this.kyc.state.value.loading;
  }

  ngOnInit(): void {
    this.kyc.setSection('LOCAL');
  }

  closeApp() {
    this.gtm.pushTag({
      event: 'LOCAL_KYC_COMPLETE',
    });
    this.modal.openOnboardModal({
      title: 'You Have Successfully Submitted Your Information For Review',
      cta: 'Close',
    });
  }
}

export type AppOptionType = {
  label: string;
  value: string;
};
export const YES_NO: AppOptionType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'NO',
    value: 'NO',
  },
];

export interface Question {
  question: string;
  options: AppOptionType[];
  controlName: string;
  children?: this[];
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface uploadState {
  ADDRESS_PROOF: boolean;
  PHOTO_ID: boolean;
  acceptedDocuments: string[];
}

const initialState: uploadState = {
  ADDRESS_PROOF: false,
  PHOTO_ID: false,
  acceptedDocuments: [],
};
@Injectable({
  providedIn: 'root',
})
export class UploadStateService {
  state = new BehaviorSubject<uploadState>(initialState);

  documentUpload(itemParam: string) {
    this.state.next({
      ...this.state.getValue(),
      [itemParam]: true,
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastXWords',
})
export class LastXWords implements PipeTransform {
  transform(word: string, length: number, appendText: string = ''): string {
    try {
      if (typeof word === 'undefined') {
        return word;
      }

      if (word.length <= length) {
        return word;
      }

      const lastXWords = word.substr(word.length - length);

      return Boolean(appendText) ? `${appendText} ${lastXWords}` : lastXWords;
    } catch (error) {
      return word;
    }
  }
}

<div class="flex flex-col">
  <h3 class="fs-18 fw-600 mb-0 dark-01">Compliance Profile</h3>
  <p class="fs-14 mt-0 grey-02 mb-5">
    Enter your mother’s maiden (first) name
  </p>
  <mat-form-field class="w-full my-2" appearance="outline">
    <mat-label>Your Marital Status <span style="color: red">*</span></mat-label>
    <mat-select [formControl]="maritalStatus" placeholder="Choose LGA">
      <mat-option
        *ngFor="let item of martialStatusOptions"
        [value]="item.value"
      >
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Your Mother's Maiden Name <span style="color: red">*</span></mat-label>
    <input [formControl]="maidenName" matInput type="text" />
  </mat-form-field>
  <hr class="mb-2" />
  <form [formGroup]="nextOfKinForm" class="flex flex-col">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Name of next of Kin</mat-label>
      <input formControlName="nextOfKin" matInput type="text" />
    </mat-form-field>
    <div class="flex flex-row justify-end">
      <button (click)="skipKin()" class="cbtn cbtn-plain fs-12">
        Use Yourself
      </button>
    </div>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Enter next of KIN number</mat-label>
      <input
        minlength="11"
        maxlength="11"
        formControlName="nextOfKinPhone"
        matInput
        #input
        type="string"
      />
      <mat-hint align="end">{{ input.value?.length || 0 }}/11</mat-hint>
      <mat-error
        *ngIf="nextOfKinForm.get('nextOfKinPhone')?.errors?.wrongNumber"
        >Please input a valid phone number</mat-error
      >
    </mat-form-field>
  </form>
  <div class="flex flex-col">
    <mat-progress-bar
      *ngIf="(kyc.state | async)?.loading"
      mode="indeterminate"
    ></mat-progress-bar>
    <button
      (click)="submitForm()"
      [disabled]="formValidity"
      class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2"
    >
      Continue
    </button>
  </div>
</div>

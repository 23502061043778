import { Component, OnInit } from '@angular/core';
import { AuthStateService } from './core/auth/auth.state.service';
import { MissingKycStepService } from './onboarding/services/missing-kyc-step.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit {
  title = 'chaka-onboarding-widget';

  constructor(private auth: AuthStateService,     public steps: MissingKycStepService,){

  }

  ngOnInit(): void {
    this.steps.getMissingKYC()
    this.auth.retrieveUserProfile()
    this.addZohoScript()
  }

  addZohoScript(){
    const script = document.createElement('script');
    script.src = 'https://desk.zoho.com/portal/api/web/inapp/561656000008595140?orgId=732165917';
    script.type = 'text/javascript'
    script.id ='zoho-script'
    document.body.appendChild(script);
  }
}

import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(
  regionCode: string | undefined
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    try {
      if (!Boolean(control.value)) {
        return null;
      }

      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value,
        regionCode
      );

      if (phoneNumberUtil.isValidNumber(phoneNumber)) {
        return null;
      }

      throw new Error();
    } catch (error) {
      return { wrongNumber: { value: control.value } };
    }
  };
}

<div class="flex flex-col">
  <div *ngFor="let item of questions" class="flex flex-col w-full mb-10">
    <h6 class="fs-24 fw-500 dark-text">{{ item.question }}</h6>
    <div class="flex flex-row flex-wrap mt-6">
      <button
        (click)="setStatus(opts.value, item.controlName)"
        *ngFor="let opts of item.options"
        [ngClass]="investForm.get(item.controlName)?.value == opts.value ? 'pill-selected' : ''"
        class="{{ item.controlName === 'fundingSource' || item.controlName === 'investmentExperience' ? 'pill pill-mt' : 'pill' }}">
        {{ opts.label }}
      </button>
    </div>
  </div>
  <div [formGroup]="investForm" class="flex flex-row mt-6">
    <div class="p-1 self-center"><input class="checkbox-1_5" formControlName="acceptElectronicSignature" type="checkbox" /></div>
    <div class="ml-3 self-center">
      <p class="fs-16">I understand I am signing this agreement electronically, and that my electronic signature will have the same effect as physically signing and returning the Application Agreement.</p>
    </div>
  </div>
  <div [formGroup]="investForm" class="flex flex-row mt-3">
    <div class="p-1 self-center"><input class="checkbox-1_5" formControlName="acceptNoneUsCitizen" type="checkbox" /></div>
    <div *ngIf="downloadStateService.state | async as state" class="ml-3 self-center">
      <p class="fs-16">
        I certify that I am not a US citizen, US resident alien or other US person for US tax purposes and I am submitting the applicable form
        <span (click)="handleDownloadW8BenTemplate()" class="underline text-blue-600 fw-600 pointer">W-8 BEN</span>
        with this form to certify my foreign status and, if applicable, claim tax treaty benefits.
      </p>
      <mat-progress-bar *ngIf="state?.loading" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div class="flex flex-col my-4">
    <mat-progress-bar *ngIf="(kyc.state | async)?.loading" mode="indeterminate"></mat-progress-bar>
    <button (click)="submit()" [disabled]="getFormValidity" class="cbtn cbtn-primary py-3 fs-16 mt-4">Continue</button>
  </div>
</div>

import {
  IAcceptedDocuments,
  UploadType,
} from '../services/onboarding.interface';
import { faker } from '@faker-js/faker';

export const ACCEPTED_DOCUMENTS: IAcceptedDocuments[] = [
  {
    id: 1,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.IDENTITY,
    description: "Driver's License",
    name: "Driver's License",
  },
  {
    id: 2,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.IDENTITY,
    description: 'International Passport',
    name: 'International Passport',
  },
  {
    id: 3,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.IDENTITY,
    description: 'National ID Card',
    name: 'National ID Card',
  },
  {
    id: 4,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.IDENTITY,
    description: 'National Identification Number',
    name: 'National Identification Number',
  },
  {
    id: 5,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.IDENTITY,
    description: "Voter's Card",
    name: "Voter's Card",
  },
  {
    id: 6,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.PROOF_OF_ADDRESS,
    description: 'Utility Bill',
    name: 'Utility Bill',
  },
  {
    id: 7,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.PROOF_OF_ADDRESS,
    description: 'Bank Statement (showing address)',
    name: 'Bank Statement (showing address)',
  },
  {
    id: 8,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.PROOF_OF_ADDRESS,
    description: "Driver's License (showing address)",
    name: "Driver's License (showing address)",
  },
  {
    id: 9,
    createdBy: faker.person.fullName(),
    dateCreated: faker.date.past().toDateString(),
    dateModified: faker.date.past(),
    modifiedBy: faker.person.fullName(),
    documentType: UploadType.PROOF_OF_ADDRESS,
    description: 'NIN Slip Showing Address',
    name: 'NIN Slip Showing Address',
  },
];

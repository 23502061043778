<div class="flex flex-col">
  <div class="flex w-full justify-center">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#00B19A"/>
      <path d="M51.4503 12.7312L26.6876 38.3689L26.7314 41.1251H27.8689L54.6878 19.5125C53.9003 17.1063 52.8065 14.7875 51.4503 12.7312Z" fill="#37A18E"/>
      <path d="M55.4754 11.5063L50.5316 6.60623C49.8753 5.94994 48.7816 5.94994 48.0816 6.60623L26.9502 28.4376L17.8064 19.4251C17.1501 18.7688 16.0564 18.7688 15.3564 19.4251L10.9814 23.7564C10.3251 24.4127 10.3251 25.5064 10.9814 26.1627L25.594 40.6439C25.9878 41.0377 26.5128 41.1689 27.0378 41.1251C27.5627 41.1689 28.0877 41.0376 28.4815 40.6439L55.4754 13.9563C56.1317 13.2562 56.1317 12.1625 55.4754 11.5063Z" fill="white"/>
      <path d="M28.4815 40.6441L55.4754 13.9564C56.1316 13.3001 56.1316 12.2064 55.4754 11.5501L54.6879 10.8064L26.9939 38.019L11.6813 23.1002L11.0251 23.7565C10.3688 24.4128 10.3688 25.5066 11.0251 26.1629L25.6377 40.6442C26.0315 41.038 26.5565 41.1692 27.0815 41.1254C27.5627 41.1691 28.0877 41.0379 28.4815 40.6441Z" fill="#F2F1EF"/>
      </svg>
  </div>
  <div class="flex justify-center my-6">
      <p class="fs-16 text-center c-case">
       {{ data.title  }}
      </p>
  </div>
  <button (click)="closeApp()"  class="cbtn cbtn-primary fs-16 py-3 c-case">
    {{ data.cta  }}
  </button>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService } from 'src/app/core/auth/auth.state.service';
import { CloseAppService } from 'src/app/shared/services/close-app.service';
import { MissingKycStepService } from '../services/missing-kyc-step.service';
import { OnboardingStateService } from '../services/onboarding-state.service';
import { VerificationChoiceEnum } from '../services/onboarding.interface';

@Component({
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  sectionItems: string[] = ['General', 'Global', 'Local'];
  choiceEnum = VerificationChoiceEnum;
  constructor(
    public kyc: OnboardingStateService,
    public steps: MissingKycStepService,
    public auth: AuthStateService,
    private closeService: CloseAppService,
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  closeApp() {
    this.closeService.closeApp('SHELL COMPONENT');
  }
}

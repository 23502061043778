import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  KycState,
  OnboardingStateService,
} from '../services/onboarding-state.service';
import { VerificationChoiceEnum } from '../services/onboarding.interface';

@Component({
  selector: 'verification-choice',
  template: `
    <div class="flex-col">
      <h3 class="fs-18 fw-600 mb-0 dark-01">Verify your identity</h3>
      <p class="fs-14 mt-0 grey-02 mb-5">
        Verify your identity by providing us with either your bank verification
        number (BVN) or by inputting your personal bank details
      </p>

      <div class="form-wrapper">
        <ng-container *ngFor="let choice of choices; let i = index">
          <label [for]="choice.value" class="block mb-4">
            <input
              type="radio"
              [id]="choice.value"
              [formControl]="verificationChoice"
              [value]="choice.value"
              style="display:none"
              (ngModelChange)="kyc.updateVerificationChoice($event)"
            />
            <div
              class="choice-div flex flex-row px-2 py-4 pointer"
              [class.selected]="choice.value === verificationChoice.value"
            >
              <div class="mr-3">
                <span
                  class="choice-number flex items-center justify-center"
                  >{{ i + 1 }}</span
                >
              </div>

              <div>
                <span class="fs-16 fw-500">{{ choice.title }}</span>
                <p class="m-0 p-0 mt-1 fs-14 grey-02">
                  {{ choice.description }}
                </p>
              </div>
            </div>
          </label>
        </ng-container>
      </div>

      <div class="flex flex-col">
        <mat-progress-bar
          *ngIf="kycState.loading"
          mode="indeterminate"
        ></mat-progress-bar>
        <button
          [disabled]="!verificationChoice.valid"
          (click)="kyc.nextTab(2)"
          class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2"
        >
          Continue
        </button>
      </div>
    </div>
  `,
})
export class VerificationChoiceComponent implements OnInit, OnDestroy {
  @Input() kycState: KycState = {} as KycState;
  verificationChoice!: FormControl;
  kycSub: Subscription = new Subscription;
  choices: {
    title: string;
    description: string;
    value: VerificationChoiceEnum;
  }[] = [];

  constructor(public kyc: OnboardingStateService) {
    this.choices = [
      {
        title: 'Personal Bank Account',
        description:
          'The Provide us with the phone number you will use to receive your withdrawals',
        value: VerificationChoiceEnum.PAYOUT_ACCOUNT,
      },
      {
        title: 'Bank Verification Number (BVN)',
        description:
          'Please provide us with your BVN to enable us verify your identity',
        value: VerificationChoiceEnum.BVN,
      },
    ];

    // We listen once on the state, set the form control to the default verification choice
    this.kycSub = this.kyc.state.pipe(take(1)).subscribe(({ verificationChoice }) => {
      this.verificationChoice = new FormControl(
        verificationChoice,
        Validators.required
      );
    });
  }
  ngOnDestroy(): void {
    this.kycSub.unsubscribe()
  }

  ngOnInit(): void {
  }
}

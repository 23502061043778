<div *ngIf="kyc.state | async as state" class="flex-col">
  <h3 class="fs-18 fw-600 mb-0 dark-01">
    Validate
    {{ state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT ? 'Bank Account' : 'BVN' }}
    Details
  </h3>
  <p class="fs-14 mt-0 grey-02 mb-5">
    Enter your date of birth to validate your<br />
    {{ state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT ? 'Bank Account' : 'BVN' }}
    details.
  </p>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Enter Date of Birth (mm/dd/yyyy)</mat-label>
    <input (click)="picker.open()" [formControl]="dateControl" [matDatepicker]="picker" [max]="maxDate" matInput />
    <mat-datepicker-toggle [for]="picker" matSuffix>
      <mat-icon matDatepickerToggleIcon>today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-error *ngIf="dateControl?.errors?.invalidDate">You have to be at least 18 Years Old to Sign up.</mat-error>

  <p class="fs-12 mt-5 text-center">
    Read more about our
    <a class="primary-color" routerLink="/privacy">Privacy Policy</a>
  </p>
  <div class="flex flex-col">
    <mat-progress-bar *ngIf="kycState.loading" mode="indeterminate"></mat-progress-bar>
    <button (click)="submit()" [disabled]="formValidity" class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2">
      Connect
      {{ state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT ? 'Bank Account' : 'BVN' }}
    </button>
    <button (click)="kyc.nextTab(2)" class="cbtn cbtn-plain fs-16 py-3 px-20 mt-2">Back</button>
  </div>
</div>

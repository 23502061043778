<div class="px-4 py-3 lg:px-9 lg:py-6 flex justify-between fixed -top-1 w-full bg-white z-30">
  <img src="https://res.cloudinary.com/chakasksk/image/upload/v1630774255/Chaka_Logo_vgftxd.svg" alt="Chaka Logo">
  <div class="flex items-center self-end justify-end">
      <a routerLink="/"  class="primary-color cursor-pointer px-0 py-0 mx-0" >
        <mat-icon>arrow_forward</mat-icon>
      </a>
  </div>
</div>

<div class="flex flex-col w-full px-2 py-4 mt-16">
  <div class="press-release-main-div">
    <p class="press-release-main-title">Privacy Policy</p>
    <div class="tab-content" id="v-pills-tabContent">
      <div
        class="tab-pane  show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <p class="custom-mini-header">We Value Your Privacy</p>
        <p class="press-release-main-text">
          At Chaka Technologies Limited. (hereinafter called “Chaka”), we
          treat your personal information as private and confidential. We
          are dedicated to protecting your privacy and providing you with
          the highest level of security at any point of interaction with
          us. This Privacy Policy describes what personal information we
          collect, what we do with it and how we protect it.
        </p>
        <p class="press-release-main-text">
          This policy (together with our Terms and Conditions) sets out
          the basis on which any personal data we collect from you, or that
          you provide to us, will be processed by us. Please read the
          following carefully to understand our views and practices
          regarding your personal data and how we will treat it.
        </p>
        <p class="press-release-main-text">
          By continuing to visit our website (https://chaka.ng/) and using
          our mobile application (Chaka App) (together the Platforms) as
          well as other sites we own and operate, you accept and consent
          to the practices described in this policy. For the purposes of
          clarity, “we,” “us,” “our,” “Chaka” “Chaka Technologies” refers
          to Chaka Technologies Limited. The “Services” refer to the use
          of any of the services or products on any of the Platforms.
          “You” refers to consumers of the Services. “Personal
          Information,” or “Personal Data” means any information that
          identifies or can be used to identify you, directly or
          indirectly, including, but not limited to name, email address,
          mobile number, photograph, bank details or IP address.
        </p>
        <p class="custom-mini-header">Information We Collect and Use</p>
        <ol>
          <li>
            <p class="press-release-main-text">
              We only ask for personal information when we truly need it
              to provide a service to you. We collect it by fair and lawful
              means, with your knowledge and consent. We also let you know
              why we’recollecting it and how it will be used.
            </p>
          </li>
          <li>
            <p class="press-release-main-text">
              We collect information about you from a variety of sources,
              such as: website visits, applications,identification
              documents, personal financial statements, interactions with
              relationship managers,and other third parties and other
              written or electronic communication reflecting information
              such asyour name, address, passport details, identification
              numbers, telephone number, occupation,assets, income and any
              other we deem necessary.
            </p>
          </li>
          <li>
            <p class="press-release-main-text">
              We only retain collected information for as long as
              necessary to provide you with your requested service. What
              data we store, we’ll protect within commercially acceptable
              means to prevent loss and theft, as well as unauthorised
              access, disclosure, copying, use or modification.
            </p>
          </li>
          <li>
            <p class="press-release-main-text">
              We don’t share any personally identifying information
              publicly or with third-parties, except when required to by
              law.
            </p>
          </li>
        </ol>
        <p class="custom-mini-header">
          Non-Personal Information Collected by Us
        </p>
        <p class="press-release-main-text">
          In order to achieve our goal of providing you with the best
          service, we sometimes collect certain information during your
          visits to perform certain tasks such as grant you access to some
          parts of our web site or conduct research on your behaviour on
          our site in order to improve our services. We will not disclose
          your information to any person outside our organization except as
          described in this Privacy Policy.
        </p>
        <p class="custom-mini-header">Cookies</p>
        <p class="press-release-main-text">
          Cookies are small text files stored on your computer or mobile
          devices whenever you visit a website. Cookies have many uses,
          such as memorizing your preferences to tailor your experiences
          while on our site– or to help us analyse our website traffic.
        </p>
        <p class="press-release-main-text">
          Chaka uses cookies to improve your experience while on the
          Platforms. We would like to let you know a few things about our
          cookies:
        </p>
        <ol>
          <li class="press-release-main-text">
            Some cookies are essential to access certain areas of the
            Platforms
          </li>
          <li class="press-release-main-text">
            We use analytics cookies to help us understand how you use our
            site to discover what content is most useful to you.
          </li>
        </ol>
        <p class="press-release-main-text">
          Kindly refer to our <span class="ft7">cookie policy</span> for
          more information on cookies.
        </p>
        <p class="custom-mini-header">
          Consent for Collection, Use and Disclosure
        </p>
        <p class="press-release-main-text">
          Your use of the Platforms and/or registration for the Services
          constitutes your consent to the terms of this Privacy Policy. If
          you do not agree, you can withdraw your consent at any time but
          please note that we will not be able to provide you with our
          Services. If you provide Personal Information of any third party
          to us, we assume that you have obtained the required consent
          from the relevant third party to share and transfer his/her
          Personal Information to us.
        </p>
        <p class="custom-mini-header">Use of Your Personal Information</p>
        <p class="press-release-main-text">
          The collection and use of Personal Data by Chaka is guided by
          certain principles and any personal information provided by you
          to Chaka will be used with your consent, or under the following
          under listed instances:
        </p>
        <ol>
          <li class="press-release-main-text">
            Cases where processing of personal data is required for the
            fulfillment of a contractual obligation.
          </li>
          <li class="press-release-main-text">
            Cases where processing of personal data is required for
            compliance with legal and/or regulatory requirements.
          </li>
          <li class="press-release-main-text">
            Cases where processing is required to protect your vital
            interest or that of any other natural person.
          </li>
          <li class="press-release-main-text">
            Cases where processing is required for an activity to be
            carried in significant public interest.
          </li>
          <li class="press-release-main-text">
            Cases where processing is required for legitimate interests of
            Chaka or a third party insofar as this does not conflict with
            the requirements for the protection of your personal data.
          </li>
        </ol>
        <p class="custom-mini-header">
          With your consent as provided under this Policy, your personal
          information is used in:
        </p>
        <ol>
          <li class="press-release-main-text">
            Updating and enhancing Chaka’s records
          </li>
          <li class="press-release-main-text">
            Executing your instructions.
          </li>
          <li class="press-release-main-text">
            Establishing your identity and assessing applications for our
            products and services
          </li>
          <li class="press-release-main-text">
            Pricing and designing our products and services
          </li>
          <li class="press-release-main-text">
            Administering our products and services to you
          </li>
          <li class="press-release-main-text">
            Managing our relationship with you
          </li>
          <li class="press-release-main-text">Managing our risks</li>
          <li class="press-release-main-text">
            Identifying and investigating illegal activity (i.e.), such as
            fraud
          </li>
          <li class="press-release-main-text">
            Contacting you, for example in instances where we suspect
            fraud on your account
          </li>
          <li class="press-release-main-text">
            Conducting and improving our businesses and improving your
            experience with us
          </li>
          <li class="press-release-main-text">
            Preventing money laundering or terrorism financing activities.
          </li>
          <li class="press-release-main-text">
            Complying with our legal obligations and assisting government
            and law enforcement agencies or regulators/supervisors
          </li>
          <li class="press-release-main-text">
            Identifying and informing you about other products or services
            that we think may be of interest to you.
          </li>
          <li class="press-release-main-text">
            Processing your job application if you apply for a job with
            us.
          </li>
        </ol>
      </div>
      <div id="page_3">
        <p class="press-release-main-text">
          We may also collect, use and exchange your information in other
          ways permitted by law.
        </p>
        <p class="custom-mini-header">Automated Processing</p>
        <p class="press-release-main-text">
          We sometimes use automated systems and software to help us reach
          decisions about you, for example, to carry out security, fraud
          and money laundering checks, or to process your data when you
          apply for some of our products and services.
        </p>
        <p class="press-release-main-text">
          This type of processing is carried out under lawful basis and
          you can contact us to request that automated processing be
          reviewed by a human being if you detect any inaccuracies in your
          personal data.
        </p>
        <p class="custom-mini-header">Information We Share</p>
        <p class="press-release-main-text">
          We may share the information about you and your dealings with
          us, to the extent permitted by law and at all times in
          accordance with our legal obligations under the Nigerian Data
          protection Regulation (NDPR) and other extant data protection
          laws applicable to us, with the following persons and in the
          following instances:
        </p>
        <ul>
          <li class="press-release-main-text">
            Employees, independent contractors, subsidiaries, affiliates,
            consultants, business associates, service providers, suppliers
            and agents, acting on our behalf for any of the identified
            purposes. We also provide information to representatives and
            advisors, including attorneys and accountants, to help us
            comply with legal, accounting, or security requirements.
          </li>
          <li class="press-release-main-text">
            Regulators/Supervisors, Government Agencies/courts - It may be
            necessary by law, legal process, litigation, and/or requests
            from public and governmental authorities within or outside
            your country of residence for Chaka to disclose your personal
            information. We may also disclose information about you if we
            determine that for purposes of national security, law
            enforcement, or other issues of public importance, disclosure
            is necessary or appropriate.
          </li>
          <li class="press-release-main-text">External Auditors.</li>
          <li class="press-release-main-text">
            Chaka’s strategic partners/service providers – for the purpose
            of improving and providing our products and services to you.
            Your Personal information will not be shared with third
            parties for their marketing purposes.
          </li>
        </ul>
      </div>
      <div id="page_4">
        <p class="custom-mini-header">
          We may also disclose information about you:
        </p>
        <ul>
          <li class="press-release-main-text">
            if we determine that disclosure is reasonably necessary to
            enforce our terms and conditions or protect our operations or
            users.
          </li>
          <li class="press-release-main-text">
            In the event of business transfers which may occur when we
            sell or buy businesses or assets. In the event of a
            reorganization, merger, sale or similar event, Personal Data
            may be part of the transferred assets, however, any acquirer
            will be subject to our obligations under this Privacy Policy,
            including your rights to access and choice.
          </li>
          <li class="press-release-main-text">
            Information about our customers and their usage of our website
            is not shared with third parties for their marketing purposes.
            We do not disclose any information about any user’s usage of
            our web site except in specific cases, and we do not share
            information with any unaffiliated third parties for marketing
            purposes unless you expressly give us permission to do so.
          </li>
        </ul>
        <p class="custom-mini-header">How We Protect Your Information</p>
        <p class="press-release-main-text">
          We take appropriate technical and organizational measures to
          prevent loss, unauthorized access, misuse, modification or
          disclosure of information under our control. This may include
          the use of encryption, access controls and other forms of
          security to ensure that your data is protected. We require all
          parties including our staff and third-parties processing data on
          our behalf to comply with relevant policies and guidelines to
          ensure confidentiality and that information is protected in use,
          when stored and during transmission. Our security controls and
          processes are also regularly updated to meet and exceed industry
          standards.
        </p>
        <p class="press-release-main-text">
          Where we have provided you (or where you have chosen) a password
          or access or authentication code or information which grants you
          access to specific areas on our site, you are responsible for
          keeping this password confidential. We request that you do not
          to share your password or other authentication with anyone. If
          you do, you will be responsible for all actions taken in the
          name of your account and the consequences. If you lose control
          of your password, you may lose substantial control over your
          Personal Information and other information submitted to us.
          Therefore, if your password has been compromised for any reason
          or if you have grounds to believe that your password has been
          compromised, you should immediately contact us and change your
          password.
        </p>
        <p class="custom-mini-header">Where We Store Your Information</p>
        <p class="press-release-main-text">
          All Personal Information you provide to us is stored on our
          secure servers as well as secure physical locations and cloud
          infrastructure (where applicable). We may also subcontract
          processing or storage of information to third parties and the
          data that we collect from you may be transferred to and stored
          at a destination outside Nigeria or the European Economic Area
          (“EEA”), including countries that do not have laws providing
          specific protection for personal data or those that have
          different legal rules on data protection. Whenever your
          information is transferred to another location, we will take all
          necessary steps to ensure that your data is handled securely and
          in accordance with this Policy.
        </p>
      </div>
      <div id="page_5">
        <p class="press-release-main-text">
          We will only transfer Personal Information outside the country
          upon compliance with the provisions of any applicable law in
          relation to transfer of Personal Information outside Nigeria.
        </p>
        <p class="press-release-main-text">
          By this Privacy Policy, you hereby explicitly and contractually
          consent to the transfer of your Personal Information by the
          Company as contemplated herein.
        </p>
        <p class="custom-mini-header">
          How Long We Store Your Information
        </p>
        <p class="press-release-main-text">
          We retain your data for as long as is necessary for the business
          and/or legal purposes that it was collected. Storage of your
          data is also determined by legal, regulatory, administrative or
          operational requirements. We only retain information that allows
          us comply with legal and regulatory requests for certain data,
          meet business and audit requirements, respond to complaints and
          queries, or address disputes or claims that may arise.
        </p>
        <p class="press-release-main-text">
          In addition, we may retain your information for an additional
          period as is permitted or required to, among other things,
          comply with our legal obligations, resolve disputes, and enforce
          agreements.
        </p>
        <p class="press-release-main-text">
          Data which is not retained is securely destroyed when it is
          identified that is no longer needed for the purposes for which
          it was collected.
        </p>
        <p class="custom-mini-header">Your Rights</p>
        <p class="press-release-main-text">
          You have certain rights available to you, these include
        </p>
        <ul>
          <li class="press-release-main-text">
            <span class="press-release-main-text"
              >The right to access your personal information held by us.
              Your right of access can be exercised by sending an email to </span
            ><a href="mailto:compliance@chaka.ng"
              ><span>compliance@chaka.ng</span></a
            >
          </li>
          <li class="press-release-main-text">
            The right to rectify inaccurate or incomplete information.
          </li>
          <li class="press-release-main-text">
            withdraw consent for processing in cases where consent has
            previously been given.
          </li>
          <li class="press-release-main-text">
            restrict or object to processing of your personal data. We
            might continue to process your data if there are valid legal
            reasons.
          </li>
          <li class="press-release-main-text">
            lodge a formal complaint with the appropriate supervisory
            authority should you feel your data privacy rights are not
            being adequately protected by us.
          </li>
        </ul>
        <p class="custom-mini-header">You also have the right to:</p>
        <ul>
          <li class="press-release-main-text">
            Request that your personal data be made available to you in a
            common electronic format and/or request that such data be sent
            to a third party.
          </li>
          <li class="press-release-main-text">
            Request that your information be erased. We might continue to
            retain such data if there are valid legal, regulatory or
            operational reasons.
          </li>
        </ul>
        <p class="custom-mini-header">Maintaining Accurate Information</p>
        <p class="press-release-main-text">
          Keeping your account information accurate and up to date is very
          important. You represent that all Personal Information provided
          to us is accurate for the identified purpose.
        </p>
      </div>
      <div id="page_6">
        <p class="press-release-main-text">
          You have access to your account information, which includes your
          contact information, account balances, transactions and similar
          information through various means, thus you are responsible for
          informing us about any changes to your Personal Information from
          time to time.
        </p>
        <p class="press-release-main-text">
          If you discover any inaccuracies in your personal information,
          please promptly notify us, via our e-channels, and provide the
          required documentary evidence, to enable us to implement the
          necessary updates or changes.
        </p>
        <p class="custom-mini-header">Third-Party Sites and Services</p>
        <p class="press-release-main-text">
          Chaka’s websites, products, applications, and services may
          contain links to third-party websites, products and services.
          Our products and services may also use or offer products or
          services from third parties. Information collected by third
          parties, which may include such things as location data or
          contact details is governed by their privacy practices and Chaka
          will not be liable for any breach of confidentiality or privacy
          of your information on such sites. We are not responsible for
          the privacy practices of any linked third-party website. If you
          have questions about the privacy policies or practices of such
          linked websites; you should contact the web administrator of the
          site directly. We also encourage you to learn about the privacy
          practices of those third parties.
        </p>
        <p class="custom-mini-header">Social Media Platforms</p>
        <p class="press-release-main-text">
          Chaka may interact with registered users of various social media
          platforms, including
          <a href="https://twitter.com/ChakaStocks"
            ><span class="press-release-main-text">Twitter</span></a
          >Facebook, LinkedIn and Instagram. Please note that any content
          you post to such social media platforms (e.g. pictures,
          information or opinions), as well as any personal information
          that you otherwise make available to users (e.g. your profile)
          is subject to the applicable social media platform’s terms of
          use and privacy policies. We recommend that you review this
          information carefully in order to better understand your rights
          and obligations with regard to such content.
        </p>
        <p class="custom-mini-header">Privacy of Children</p>
        <p class="press-release-main-text">
          Chaka’s Policy contains the following disclosure statement for
          children:
        </p>
        <p class="press-release-main-text">
          "Chaka respects the privacy of children. We do not knowingly
          collect names, email addresses or any other personally
          identifiable information from children. We do not knowingly
          market to children nor do we allow children under 18 to open
          online accounts." Consequently, Chaka shall not be liable for
          any use or processing of Personal Information of persons under
          the age of 18.
        </p>
        <p class="custom-mini-header">Promotional Messages</p>
        <p class="press-release-main-text">
          Chaka may sometimes contact you with products or services that
          we think may be of interest to you. If you don’t want to receive
          such promotional materials from us, you can opt out at any time
          by sending an email to
          <a href="mailto:compliance@chaka.ng"
            ><span class="ft8">compliance@chaka.ng</span></a
          >
        </p>
        <p class="press-release-main-text">
          Additional Privacy Policy for applicable subjects as per the
          European General Data Protection Regulation
        </p>
        <p class="custom-mini-header">Privacy Policy Changes</p>
      </div>
      <div id="page_7">
        <p class="press-release-main-text">
          This policy may be revised on an ad-hoc basis to reflect the
          legal, regulatory and operating environment. We will post any
          revisions we make to our Privacy Policy on this page and will
          notify you by email regarding material changes to this Privacy
          Policy that will affect information collected from you in the
          future. Such revised policy becomes effective as at the time it
          is posted. You are advised to consult this privacy policy
          regularly for any changes, as we deem your continued use,
          following posting of any amendment, modification or change, as
          an approval of all changes.
        </p>
        <p class="custom-mini-header">Contact</p>
        <p class="press-release-main-text">
          Questions, comments, and requests regarding this privacy policy
          are welcomed and should be addressed to
          <a href="mailto:compliance@chaka.ng"
            ><span class="press-release-main-text"
              >compliance@chaka.ng</span
            ></a
          >
        </p>
        <p class="custom-mini-header">
          To contact our Data Protection Officer, kindly address your
          request to
          <a href="mailto:compliance@chaka.ng"
            ><span class="press-release-main-text"
              >compliance@chaka.ng</span
            ></a
          >
        </p>
      </div>
    </div>
  </div>
</div>

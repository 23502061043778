export interface LoginResponse {
  access_token: string;
  token_type: string;
  scope: string;
  AUTHORITIES: string[];
  chakaId: string;
  ENTITIES: string[];
  userId: string;
  email: string;
  jti: string;
}

/**
 * APIResponse format from Chaka API's
 */
export type APIResponse<T> = ReqSuccessResponse<T> | PaginatedList<T> | ReqFailureResponse;

/**
 * Clean a paginated response
 */
export interface PaginatedList<T> {
  /**
   * items list
   */
  data: T[];
  /**
   * the current page number
   */
  pageNumber: number;
  /**
   * the amount of items in the list
   */
  pageSize: number;
  /**
   * total number of data available
   */
  count: number;
}

/**
 * Succesful API response for chaka servers
 */
export interface ReqSuccessResponse<T> {
  message: string;
  /**
   * status is always true for successful request
   */
  status: number;
  /**
   * response data object type
   */
  data: T;
  /**
   * pagination data for paginated responses
   */
  pagination?: unknown;
}

/**
 * Failure response sent by chaka server
 * for error during operations
 */
export interface ReqFailureResponse {
  /**
   * status is false for failed operations
   */
  status: number;
  /**
   * Error message from the server
   * during processing and operations
   */
  message: string;
  errors: FormError[];
}

/**
 * details about form field errors
 */
export interface FormError {
  message: string;
  fieldName: string;
}

export interface ChakaAPIError extends Error {
  error: ReqFailureResponse;
}

export function cleanChakaAPIError(res: Error | ChakaAPIError) {
  if (res instanceof Error) return res.message;

  const { message, error } = res as ChakaAPIError;

  if (error) {
    // handle form errors which is an array
    if (error.errors) {
      return error.errors.reduce((prev, cur) => prev + `${cur.fieldName} ${cur.message} \n`, '');
    }
    return error?.message;
  }

  return message;
}

export interface UtilApiResponse<T> {
  statusCode: number;
  message: string;
  path: string;
  results: T[];
  result: T | undefined;
}

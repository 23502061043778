<div class="flex-col">
  <div class="flex flex-col w-full mb-10">
    <h6 class="fs-24 fw-500 dark-text mb-10">What is Your Employment Status</h6>
    <div class="flex flex-row">
      <button (click)="setStatus(false, 'employment', item.value)" *ngFor="let item of employmentOptions" [ngClass]="selectedEmployment === item.value ? 'pill-selected' : ''" class="pill">
        {{ item.name }}
      </button>
    </div>
  </div>
  <div *ngIf="selectedEmployment == employmentEnum.EMPLOYED || selectedEmployment == employmentEnum.SELF_EMPLOYED" class="flex flex-col mb-8">
    <form [formGroup]="companyInfo">
      <mat-form-field class="w-full">
        <mat-label>Company Name</mat-label>
        <input #input formControlName="employmentCompany" matInput type="text" />
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Industry</mat-label>
        <mat-select formControlName="employmentType" placeholder="Select Company Industry">
          <mat-option *ngFor="let item of employmentTypes" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Role</mat-label>
        <mat-select formControlName="employmentPosition" placeholder="Role">
          <mat-option *ngFor="let item of employmentPositions" [value]="item.value">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Employer Address</mat-label>
        <input #input formControlName="employerAddress" matInput type="text" />
      </mat-form-field>
    </form>
  </div>

  <div *ngFor="let item of questions" class="flex flex-col w-full mb-10">
    <h6 class="fs-24 fw-500 dark-text mb-5">{{ item.question }}</h6>
    <div *ngIf="item.children">
      <div *ngFor="let child of item.children" class="flex flex-row">
        <div class="mt-2">
          <div class="bullet">
            <mat-icon [inline]="true">circle</mat-icon>
          </div>
        </div>
        <div class="ml-3">
          <h6 class="fs-18 fw-400 dark-text">{{ child.question }}</h6>
          <div class="flex flex-row my-3">
            <button (click)="setStatus$(childOpts.value, child.controlName)" *ngFor="let childOpts of child.options" [ngClass]="personalInfoForm.get(child.controlName)?.value == childOpts.value ? 'pill-selected' : ''" class="pill">
              {{ childOpts.label }}
            </button>
          </div>
        </div>
      </div>
      <div [formGroup]="personalInfoForm" class="flex flex-row mt-5">
        <div class="p-1"><input class="checkbox-1_5" formControlName="noneApplyExtraInvestmentInfo" type="checkbox" /></div>
        <div class="ml-3 self-center"><p>None of the above apply to me or my family.</p></div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap mt-6">
      <button (click)="setStatus$(opts.value, item.controlName)" *ngFor="let opts of item.options" [ngClass]="personalInfoForm.get(item.controlName)?.value == opts.value ? 'pill-selected' : ''" class="pill">
        {{ opts.label }}
      </button>
    </div>
  </div>
  <div class="flex flex-col my-4">
    <mat-progress-bar *ngIf="(kyc.state | async)?.loading" mode="indeterminate"></mat-progress-bar>
    <button (click)="submit()" [disabled]="getFormValidity" class="cbtn cbtn-primary fs-16 py-3 px-20 mt-4">Continue</button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { IModalParam } from '../../../shared/services/modal.interface';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CompletedInvestmentInfoVerificationComponent } from './completed-investment-info-verification.component';
import { CompletedInvestmentInfoVerificationService } from './completed-investment-info-verification.service';

@Injectable({
  providedIn: 'root',
})
export class CompletedInvestmentVerificationModalService {
  dialogRef!: MatDialogRef<any>;

  constructor(
    public dialog: MatDialog,
    private completedInvestmentInfoVerificationService: CompletedInvestmentInfoVerificationService,
  ) {}

  openInvestmentInfoModal(param: IModalParam) {
    const dialogConfig = new MatDialogConfig<IModalParam>();
    dialogConfig.width = '285px';
    dialogConfig.data = param;

    this.dialogRef = this.dialog.open(CompletedInvestmentInfoVerificationComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result: any) => {
      // this.completedInvestmentInfoVerificationService.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

export const environment = {
  production: false,
  iframeSrc: 'https://advisory-sdk-staging.chaka.com/',
  enterpriseURL: `https://enterprise-staging.chaka.com`,
  authURL: `https://auth-staging.chaka.com`,
  interswitchMode: 'TEST',
  equityUrl: 'https://staging-chaka.com',
  tagManager: 'GTM-N8VKJ75',
  w8BenFileUrl: 'https://chaka-storage.s3.eu-west-1.amazonaws.com/w-8ben.pdf',
};

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, skipWhile, takeUntil } from 'rxjs/operators';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';
import { FilePosition, FileUpload, IAcceptedDocuments, SupportedDocTypeEnum, UploadType } from '../services/onboarding.interface';
import { UploadStateService } from '../services/upload-state.service';
import { ACCEPTED_DOCUMENTS } from './constants';

@Component({
  selector: 'document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  identityForm!: FormGroup;
  proofOfAddressForm!: FormGroup;

  uploadTypeEnum = UploadType;

  acceptedDocuments: Map<UploadType, IAcceptedDocuments[]> = new Map();
  docDescription: Map<UploadType, string> = new Map();
  fileName: Map<UploadType, string> = new Map();

  payload: Map<UploadType, FileUpload> = new Map();

  destroyed$: Subject<boolean> = new Subject();

  userId = '';

  constructor(
    public uploadState: UploadStateService,
    public kyc: OnboardingStateService,
    private _snackBar: MatSnackBar,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private documentPreview: ModalService,
    private imageCompress: CompressImageService,
  ) {
    this.matIconRegistry.addSvgIcon('upload-cancel', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/upload-cancel.svg'));
  }

  public get isNinSlip(): boolean {
    return this.identityForm.controls.documentType.value === SupportedDocTypeEnum.NATIONAL_IDENTITY_NUMBER;
  }

  public get formDisabled(): boolean {
    return (
      !this.identityForm.valid ||
      !this.proofOfAddressForm.valid ||
      !Boolean(this.payload.get(UploadType.IDENTITY)?.file) ||
      !Boolean(this.payload.get(UploadType.IDENTITY_REAR)?.file) ||
      !Boolean(this.payload.get(UploadType.PROOF_OF_ADDRESS)?.file)
      // !Boolean(this.payload.get(UploadType.PROOF_OF_ADDRESS_REAR)?.file)
    );
  }

  ngOnInit(): void {
    this.kyc.setSection('GLOBAL');

    // this.loadFakeDocuments();
    this.kyc.getSupportedDocuments(() => {
      this.listenToKycState();
    });

    this.initDocumentForms();
  }

  initDocumentForms() {
    this.identityForm = new FormGroup({
      documentType: new FormControl('', Validators.required),
      nin: new FormControl('', Validators.pattern('^[0-9]{11}$')),
    });

    this.proofOfAddressForm = new FormGroup({
      documentType: new FormControl('', Validators.required),
    });
  }

  onDocTypeChange(docName: string, uploadType: UploadType) {
    this.resolveDocumentDescription(docName, uploadType);
    this.mutateDocumentDescription(uploadType);

    if (uploadType === UploadType.IDENTITY) {
      this.toggleNinValidation(docName);
    }
  }

  async browseFile(event: any, uploadType: UploadType) {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file.type.includes('image') && !file.type.includes('pdf')) {
      this._snackBar.open('Only Images and PDFs can be uploaded', `Close`);
      this.fileName.delete(uploadType);
      this.payload.delete(uploadType);
      return;
    }

    if (event.target.files.length > 1) {
      this._snackBar.open('You can only upload one image.', `Close`);
      this.fileName.delete(uploadType);
      this.payload.delete(uploadType);
      return;
    }

    if (file.size > 3145728) {
      this._snackBar.open('You can only upload a maximum file size of 3MB.', `Close`);
      this.fileName.delete(uploadType);
      this.payload.delete(uploadType);
      return;
    }

    let fileToSend = event.target.files[0];

    // if (file.type.includes('image')) {
    //   fileToSend = await this.imageCompress
    //     .compress(event.target.files[0])
    //     .pipe(take(1))
    //     .toPromise();
    // } else {
    //   fileToSend = event.target.files[0];
    // }

    const isIdentityFront = uploadType === UploadType.IDENTITY;
    const isIdentityRear = uploadType === UploadType.IDENTITY_REAR;
    const isProofFront = uploadType === UploadType.PROOF_OF_ADDRESS;
    const isProofRear = uploadType === UploadType.PROOF_OF_ADDRESS_REAR;
    const filePosition = isIdentityFront ? FilePosition.FRONT : isIdentityRear ? FilePosition.REAR : isProofFront ? FilePosition.FRONT : isProofRear ? FilePosition.REAR : FilePosition.FRONT;

    this.payload.set(uploadType, {
      file: fileToSend,
      userId: this.userId,
      position: filePosition,
      uploadType: isIdentityFront ? UploadType.PHOTO_ID : isIdentityRear ? UploadType.PHOTO_ID_BACK : UploadType.ADDRESS_PROOF,
    });

    this.fileName.set(uploadType, file.name);
  }

  removeFile(event: MouseEvent, uploadType: UploadType) {
    this.fileName.delete(uploadType);
    this.payload.delete(uploadType);

    event.preventDefault();
  }

  previewDocument(uploadType: UploadType) {
    this.documentPreview.open(this.payload.get(uploadType)?.file as File);
  }

  uploadDocuments() {
    this.isNinSlip
      ? this.kyc.submitNin(
          {
            nin: this.identityForm.controls.nin.value,
          },
          this.payload,
        )
      : this.kyc.uploadDocument(this.payload);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private mutateDocumentDescription(uploadType: UploadType) {
    if (uploadType.match('IDENTITY')?.input) {
      const desc = this.docDescription.get(uploadType);
      if (desc) {
        this.docDescription.set(UploadType.IDENTITY_REAR, `(Rear) ${desc}`);
        this.docDescription.set(UploadType.IDENTITY, `(Front) ${desc}`);
      }
    }

    if (uploadType.match('PROOF')?.input) {
      const desc = this.docDescription.get(uploadType);
      if (desc) {
        this.docDescription.set(UploadType.PROOF_OF_ADDRESS_REAR, `(Rear) ${desc}`);
        this.docDescription.set(UploadType.PROOF_OF_ADDRESS, `${desc}`);
      }
    }
  }

  private loadFakeDocuments() {
    ACCEPTED_DOCUMENTS.forEach(({ documentType, ...theRest }) => {
      if (!Boolean(this.acceptedDocuments.get(documentType))) {
        this.acceptedDocuments.set(documentType, []);
      }

      this.acceptedDocuments.get(documentType)?.push({ ...theRest, documentType });
    });
  }

  private listenToKycState() {
    this.kyc.state
      .pipe(
        skipWhile(state => state.loading),
        filter(state => state.acceptedDocuments.length > 0),
        takeUntil(this.destroyed$),
      )
      .subscribe(state => {
        const { profile, acceptedDocuments } = state;

        this.userId = profile.userId;
        acceptedDocuments.forEach(({ documentType, ...theRest }) => {
          if (!Boolean(this.acceptedDocuments.get(documentType))) {
            this.acceptedDocuments.set(documentType, []);
          }

          this.acceptedDocuments.get(documentType)?.push({ ...theRest, documentType });
        });
      });
  }

  private toggleNinValidation(docName: string) {
    const ninControl = this.identityForm.get('nin');

    docName === SupportedDocTypeEnum.NATIONAL_IDENTITY_NUMBER ? ninControl?.addValidators(Validators.required) : ninControl?.removeValidators(Validators.required);

    ninControl?.updateValueAndValidity();
  }

  private resolveDocumentDescription(docName: string, uploadType: UploadType) {
    try {
      const flatten = <T>(arr: T[][]): T[] => arr.reduce((flat: T[], next: T[]) => flat.concat(next), []);

      const flatAcceptedDocs = flatten(Array.from(this.acceptedDocuments.values()));

      this.docDescription.set(uploadType, `Upload ${flatAcceptedDocs.find(({ name }) => name === docName)?.description}` || '');
    } catch (error) {
      this.docDescription.set(uploadType, '');
    }
  }
}

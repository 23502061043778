import { Injectable } from '@angular/core';
import { ReqSuccessResponse } from 'src/app/core/http/api.interface';
import { BaseService } from 'src/app/core/http/base-service.service';
import { AccountInfo, Bank } from './onboarding.interface';

@Injectable({ providedIn: 'root' })
export class KYCUtilService {
  constructor(private client: BaseService) {}

  banks() {
    return this.client.get<ReqSuccessResponse<Bank[]>>(`api/v1/banks`, {pageSize: 2000});
  }

  bankAccountInfo(bankInfo: Record<string, string> = {}) {
    const url = `/api/v1/banks/name-lookup?`;
    return this.client.get<ReqSuccessResponse<AccountInfo>>(url, bankInfo);
  }
}

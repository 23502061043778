import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CloseAppService } from '../../services/close-app.service';

@Component({
  selector: 'completed-onboarding-modal',
  templateUrl: './completed-onboarding.component.html',
})
export class CompletedOnboardingComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {title:string; cta:string},
  private closeSerivce: CloseAppService
  ) {
  }

  ngOnInit(): void {}

  closeApp(){
    this.closeSerivce.closeApp(this.data.title)
  }
}

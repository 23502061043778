import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PhoneNumberValidator } from 'src/app/shared/PhoneNumber.validator';
import { OnboardingStateService } from '../services/onboarding-state.service';

@Component({
  selector: 'next-kin-info',
  templateUrl: './next-kin-info.component.html',
})
export class NextKinInfoComponent implements OnInit {
  nextOfKinForm: FormGroup;
  maritalStatus: FormControl;
  maidenName: FormControl;
  martialStatusOptions = [
    {
      label: 'Single',
      value: 'SINGLE',
    },
    { label: 'Married', value: 'MARRIED' },
    {
      label: 'Divorced',
      value: 'DIVORCED',
    },
    {
      label: 'Widowed',
      value: 'WIDOWED',
    },
  ];

  constructor(
    public kyc: OnboardingStateService,
    private gtm: GoogleTagManagerService,
  ) {
    let { nextOfKin, nextOfKinPhone, maidenName, maritalStatus } = this.kyc.state.getValue().profile;
    const { required } = Validators;
    this.nextOfKinForm = new FormGroup({
      nextOfKin: new FormControl(nextOfKin, required),
      nextOfKinPhone: new FormControl(nextOfKinPhone, [required, PhoneNumberValidator('NG')]),
    });
    this.maritalStatus = new FormControl(maritalStatus, required);
    this.maidenName = new FormControl(maidenName, required);
  }

  get formValidity(): boolean {
    return this.nextOfKinForm.invalid || this.kyc.state.value.loading || this.maritalStatus.invalid || this.maidenName.invalid;
  }

  ngOnInit(): void {
    this.kyc.setSection('LOCAL');
    this.gtm.pushTag({
      event: 'LOCAL_KYC_START',
    });
  }

  submitForm() {
    const payload = {
      ...this.nextOfKinForm.value,
      maritalStatus: this.maritalStatus.value,
      maidenName: this.maidenName.value,
    };
    this.kyc.updateKyc(payload, true);
  }

  skipKin() {
    let { fullName, phone } = this.kyc.state.getValue().profile;
    let formmattedNumber: string;
    if (phone.startsWith('234')) {
      formmattedNumber = `0${phone.slice(3)}`;
    } else {
      formmattedNumber = phone;
    }
    this.nextOfKinForm.patchValue({
      nextOfKin: fullName,
      nextOfKinPhone: formmattedNumber,
    });
  }
}

<div *ngIf="kyc.state | async as state">
  <div *ngIf="!editAddress">
    <div class="flex-col">
      <h3 class="fs-18 fw-600 mb-0 dark-01">Confirm Personal Details</h3>
      <p class="fs-14 mt-0 grey-02 mb-5">
        Please confirm that the following details<br />
        belongs to you
      </p>
      <div class="flex flex-col mt-10 mb-2">
        <span class="fs-12 grey-02">Full Name</span>
        <p class="fs-14 dark-text mb-0">
          {{ state.profile.firstName }} {{ state.profile.lastName }}
        </p>
      </div>
      <div class="flex flex-col my-2">
        <span class="fs-12 grey-02">Gender</span>
        <p class="fs-14 dark-text mb-0">{{ state.profile.gender }}</p>
      </div>
      <div class="flex flex-col my-2">
        <span class="fs-12 grey-02">Date of Birth</span>
        <p class="fs-14 dark-text mb-0">
          {{ state.profile.dob }}
        </p>
      </div>
      <div
        class="flex flex-col"
        *ngIf="state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT"
      >
        <div class="flex flex-row my-2">
          <div class="flex flex-col mr-4">
            <span class="fs-12 grey-02">Street Address</span>
            <p class="fs-14 dark-text mb-0">{{ state.profile.address }}</p>
          </div>
        </div>
        <div
          class="flex flex-row my-4"
          *ngIf="state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT"
        >
          <div class="flex-col mr-4">
            <span class="fs-12 grey-02">LGA</span>
            <p class="fs-14 dark-text">{{ state.profile.lg }}</p>
          </div>
          <div class="flex-col">
            <span class="fs-12 grey-02">State</span>
            <p class="fs-14 dark-text">{{ state.profile.state }}</p>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col"
        *ngIf="state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT"
      >
        <div class="flex flex-row justify-center">
          <button
            (click)="setForm(state.profile)"
            class="
              flex
              btn-reset
              flex-wrap
              content-center
              pointer
              fs-14
              primary-color
            "
          >
            <mat-icon>edit</mat-icon>
            <span class="self-end"> Edit Address </span>
          </button>
        </div>
        <div class="flex flex-col my-4">
          <mat-progress-bar
          *ngIf="state.loading"
          mode="indeterminate"
        ></mat-progress-bar>
          <button
          [disabled]="state.loading"
            (click)="submit(state.profile)"
            class="cbtn cbtn-md fs-16 cbtn-primary w-full mt-1 "
          >
            Continue
          </button>
        </div>
      </div>
      <div
        class="flex flex-col"
        *ngIf="state.verificationChoice === choiceEnum.BVN"
      >
        <div class="flex flex-row my-4">
          <button
            (click)="editAddress = !editAddress"
            class="cbtn cbtn-md fs-16 cbtn-primary w-full"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editAddress">
    <form [formGroup]="addressForm" class="flex flex-col">
      <h3 class="fs-18 fw-600 mb-0 dark-01">House Address</h3>
      <p class="fs-14 mt-0 grey-02 mb-5">Please Fill In Your House Address</p>
      <div class="flex justify-between">
        <mat-form-field style="width: 45%" appearance="outline">
          <mat-label>State</mat-label>
          <mat-select
            (selectionChange)="getLgas()"
            formControlName="state"
            placeholder="Choose State"
          >
            <mat-option *ngFor="let item of states" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 45%">
          <mat-label>LGA</mat-label>
          <mat-select
            (selectionChange)="setCity($event)"
            formControlName="lg"
            placeholder="Choose LGA"
          >
            <mat-option *ngFor="let item of lgas" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="w-full">
        <mat-label>Street Address</mat-label>
        <textarea formControlName="address" matInput #input type="text">
        </textarea>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Postal Code</mat-label>
        <input formControlName="postalCode" matInput #input type="text" />
      </mat-form-field>
      <div class="flex flex-col my-4">
        <mat-progress-bar
          *ngIf="state.loading"
          mode="indeterminate"
        ></mat-progress-bar>
        <button
          (click)="submit(addressForm.value)"
          [disabled]="formValidity"
          class="cbtn cbtn-primary py-3 px-20 mt-4"
        >
          Confirm Details
        </button>
        <button
          *ngIf="state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT"
          (click)="editAddress = !editAddress"
          class="cbtn cbtn-plain py-3 px-20 mt-4"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>

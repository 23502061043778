import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './privay-policy.component.html',
  styleUrls: ['./privay-policy.component.scss']
})
export class PrivayPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goback(){
    window.history.back();
  }
}

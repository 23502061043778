import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { BankStateService } from '../../services/bank-state.service';
import { KycState, OnboardingStateService } from '../../services/onboarding-state.service';

@Component({
  selector: 'bvn-bank-verification',
  templateUrl: './bvn-bank-verification.component.html',
})
export class BvnBankVerificationComponent implements OnInit, OnDestroy {
  @Output() backButton = new EventEmitter();
  bankForm: FormGroup;
  consent: FormControl = new FormControl('', Validators.required);
  accountSub: Subscription = new Subscription();
  kycSub: Subscription;
  kycState: KycState = {} as KycState;

  constructor(
    public banks: BankStateService,
    public kyc: OnboardingStateService,
  ) {
    this.bankForm = new FormGroup({
      bankId: new FormControl('', [Validators.required]),
      bankAcctNo: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.required, Validators.pattern('[0-9]+')]),
      bankAcctName: new FormControl('', [Validators.required]),
    });
    this.kycSub = this.kyc.state.subscribe(state => (this.kycState = state));
  }

  get formValidity(): boolean {
    return this.bankForm.invalid || this.consent.value != true || this.kyc.state.value.loading;
  }

  ngOnDestroy(): void {
    this.accountSub?.unsubscribe();
    this.kycSub.unsubscribe();
  }

  ngOnInit(): void {
    this.kyc.setSection('GENERAL');
    this.banks.list();
    this.createForm();
    this.accountSub = this.banks.state.pipe(skipWhile(state => state.loading)).subscribe(state => {
      this.bankForm.patchValue({
        bankAcctName: state.customerInfo?.accountName,
      });
    });
  }

  createForm() {
    this.bankForm = new FormGroup({
      bankId: new FormControl('', [Validators.required]),
      bankAcctNo: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.required, Validators.pattern('[0-9]+')]),
      bankAcctName: new FormControl('', [Validators.required]),
    });
  }

  getAccountName() {
    const { bankId, bankAcctNo } = this.bankForm.value;
    if (bankAcctNo.length == 10 && bankId && this.bankForm.get('bankAcctNo')?.valid) {
      let payload = {
        bankId,
        accountNumber: bankAcctNo,
      };
      this.banks.getAccountName(payload);
    }
  }
}

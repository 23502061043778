import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    if(value.startsWith('234')){
      return `+${value}`
    }
    let formattedNumber = `+${234}${value.slice(1)}`
    return formattedNumber;
  }

}

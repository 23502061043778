import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { ChakaAPIError, cleanChakaAPIError } from 'src/app/core/http/api.interface';
import { ModalService } from 'src/app/shared/services/modal.service';
import { OnboardingStateService } from './onboarding-state.service';
import { ImissingKYC, ITab, VerificationChoiceEnum, VerificationTypes } from './onboarding.interface';
import { OnboardingService } from './onboarding.service';
import { kycModes, tabs } from './tabMapping';

interface MissingKYCState {
  startTab: number;
  loading: boolean;
  verificationMode: string;
  enabledCats: {
    general: boolean;
    global: boolean;
    local: boolean;
  };
  kycTabs: ITab[];
  verificationName: string;
}

const initialState: MissingKYCState = {
  startTab: 0,
  loading: true,
  verificationMode: VerificationTypes.ALL,
  enabledCats: {
    general: true,
    global: false,
    local: false,
  },
  kycTabs: [],
  verificationName: '',
};

@Injectable({
  providedIn: 'root',
})
export class MissingKycStepService {
  state = new BehaviorSubject<MissingKYCState>(initialState);
  sortedTabs = tabs;
  modeData = kycModes;

  constructor(
    private kycService: OnboardingService,
    private _snackBar: MatSnackBar,
    private modalService: ModalService,
    private kycState: OnboardingStateService,
  ) {}

  getMissingKYC() {
    this.kycService.missingKyc().subscribe(
      Response => this.setTabConfig(Response.data),
      error => this.onError(error),
    );
  }

  updateState(state: Partial<MissingKYCState>) {
    this.state.next({ ...this.state.getValue(), ...state });
  }

  private setTabConfig(data: ImissingKYC) {
    let verificationMode = localStorage.getItem('verificationType') ?? VerificationTypes.ALL;
    let kycCategories = this.modeData.filter(item => item.type == verificationMode)[0];
    this.kycState.updateVerificationMode(verificationMode);
    const verificationName: string = kycCategories.name;
    this.state.next({
      ...this.state.getValue(),
      verificationMode,
      verificationName,
      enabledCats: kycCategories.categories,
    });
    let combinedVerification = [...data.general, ...data.global, ...data.local];
    let completeTab: ITab[] = [];
    this.sortedTabs.forEach(tabData => {
      combinedVerification.forEach(verificationStatus => {
        if (verificationStatus.kyc == tabData.kyc) {
          completeTab.push({
            ...tabData,
            completed: verificationStatus.completed,
          });
        }
      });
    });
    let kycMode = verificationMode.toLowerCase();
    let verificationChoice;
    let startingTabIndex;
    if (kycMode == 'all' || kycMode == 'genlocal' || kycMode == 'genglobal' || kycMode == 'general') {
      let bvnVerification = completeTab.filter(item => item.kyc == 'BVN')[0];
      let bankVerification = completeTab.filter(item => item.kyc == 'BANK')[0];
      if (bvnVerification.completed) {
        let index = completeTab.indexOf(bankVerification);
        verificationChoice = VerificationChoiceEnum.BVN;
        this.kycState.setVerificationChoice(verificationChoice);
        completeTab.splice(index, 1);
      }
      if (bankVerification.completed) {
        let index = completeTab.indexOf(bvnVerification);
        verificationChoice = VerificationChoiceEnum.PAYOUT_ACCOUNT;
        this.kycState.setVerificationChoice(verificationChoice);
        completeTab.splice(index, 1);
      }
    }
    const validTabs = completeTab.filter(item => !item.completed && item.tabIndex[`${verificationMode.toLowerCase()}`] != null);
    if (validTabs.length == 0) {
      this.modalService.openOnboardModal({
        title: `You Have Already Completed Your ${verificationName.toLowerCase()} Verification`,
        cta: 'Close',
      });
      return;
    }
    startingTabIndex = validTabs.sort((current, nextItem) => current.tabIndex[`${verificationMode.toLowerCase()}`] - nextItem.tabIndex[`${verificationMode.toLowerCase()}`])[0].tabIndex[`${verificationMode.toLowerCase()}`];
    this.state.next({
      ...this.state.getValue(),
      kycTabs: [...completeTab],
    });
    this.kycState.nextTab(startingTabIndex);
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      loading: false,
    });
    this._snackBar.open(error, `Close`);
  }
}

import { Injectable } from '@angular/core';
import { ReqSuccessResponse } from 'src/app/core/http/api.interface';
import { BaseService } from 'src/app/core/http/base-service.service';
import { Bank, Bvn, CSCSS, FileUploadData, IAcceptedDocuments, IEditPhone, ImissingKYC, IVerifyBank, IVerifyBvn, KYCProfile, NameLookupResponse, NubanInfo } from './onboarding.interface';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private client: BaseService) {}

  /**
   *
   * Sends OTP to users Email
   */
  sendEmailOtp() {
    const url = `/api/v1/kyc/user/email/validate`;
    return this.client.post<ReqSuccessResponse<any>>(url);
  }

  /**
   *
   * Validates Email OTP
   */
  validateEmailOtp(otp: String) {
    let payload = {
      otp,
      productType: 'EQUITY',
    };
    const url = `/api/v1/kyc/user/email/authenticate-otp`;
    return this.client.post<ReqSuccessResponse<KYCProfile>>(url, payload);
  }

  sendOTP() {
    return this.client.post<ReqSuccessResponse<string>>(`api/v1/kyc/user/phone/validate`, {});
  }

  confirmOTP(code: string) {
    return this.client.post<ReqSuccessResponse<string>>(`api/v1/kyc/user/phone/authenticate-otp`, { otp: code });
  }

  changePhoneNumber(data: IEditPhone) {
    return this.client.put<ReqSuccessResponse<any>>(`api/v1/kyc/user/phone`, data);
  }

  banks() {
    return this.client.get<ReqSuccessResponse<Bank[]>>(`api/v1/banks`, {
      pageSize: 2000,
    });
  }

  getCscss() {
    return this.client.get<ReqSuccessResponse<CSCSS>>(`api/v1/kyc/user/cscs`);
  }

  createCscss(cscss: CSCSS) {
    return this.client.post<ReqSuccessResponse<any>>(`api/v1/kyc/user/cscs`, cscss);
  }

  accountNameInfo(bankInfo: Record<string, any> = {}) {
    const url = `/api/v1/banks/name-lookup?`;
    return this.client.get<ReqSuccessResponse<NameLookupResponse>>(url, bankInfo);
  }

  bankAccountInfo(bankInfo: Record<string, any> = {}) {
    const url = `/api/v1/banks/nuban-lookup?`;
    return this.client.get<ReqSuccessResponse<NubanInfo>>(url, bankInfo);
  }

  profile() {
    const url = `/api/v1/users/profile`;
    return this.client.get<ReqSuccessResponse<KYCProfile>>(url);
  }

  /**
   * Gets Users BVN Details
   */
  bvnRecord(bvn: Record<string, any>) {
    return this.client.post<ReqSuccessResponse<Bvn>>(`api/v1/kyc/user/bvn`, bvn);
  }

  /**
   * Saves KYC Data to Users Profile
   */
  onboardKyc(kyc: Partial<KYCProfile>) {
    const url = `/api/v1/users/onboard`;
    return this.client.post<ReqSuccessResponse<KYCProfile>>(url, kyc);
  }

  updateKyc(kyc: Partial<KYCProfile>) {
    const url = `/api/v1/users/onboard`;
    return this.client.put<ReqSuccessResponse<KYCProfile>>(url, kyc);
  }

  /**
   * Uploads Documents
   */

  submitNin(ninPayload: { nin: string }) {
    return this.client.put<ReqSuccessResponse<FileUploadData>>(`/api/v1/kyc/user/nin-lookup`, { ...ninPayload, productType: 'EQUITY' });
  }

  uploadDocument(upload: any) {
    const formData: FormData = new FormData();
    formData.append('file', upload.file, upload.file.name);
    formData.append('userId', upload.userId);
    formData.append('uploadType', upload.uploadType);
    formData.append('position', upload.position);
    return this.client.post<ReqSuccessResponse<FileUploadData>>(`/api/v1/uploads`, formData);
  }

  getSupportDocuments() {
    return this.client.get<ReqSuccessResponse<IAcceptedDocuments[]>>(`api/v1/kyc_document_types`);
  }

  verifyBVN(payload: { bvn: string; dob: string }) {
    return this.client.post<ReqSuccessResponse<IVerifyBvn>>(`/api/v1/kyc/user/verify_bvn`, payload);
  }

  verifyBankAcct(payload: IVerifyBank) {
    return this.client.post<ReqSuccessResponse<IVerifyBvn>>(`/api/v1/banks/validate_user_account_number`, payload);
  }

  missingKyc() {
    const url = '/api/v1/kyc/user/get_missing_kyc_categorized';
    return this.client.get<ReqSuccessResponse<ImissingKYC>>(url);
  }
}

<label for="phone_number" class="fs-12">Enter Phone Number</label>
<div class="flex flex-row control-wrapper items-center">
  <div class="country-part">
    <span class="select-span" >
      <mat-icon svgIcon="country-flag" class="flag-display"></mat-icon> +234
    </span>
  </div>

    <input
      id="phone_number"
      matInput
      class="mx-2 outline-none w-full"
      maxlength="11"
      [formControl]="phoneNumberControl"
      inputmode="numeric" pattern="[0-9]*"
      [appCleanPhoneNumber]="selectedCountry.callingCode" />
</div>

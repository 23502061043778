import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'completed-investment-info-verification',
  templateUrl: './completed-investment-info-verification.component.html',
  styleUrls: ['./completed-investment-info-verification.component.scss'],
})
export class CompletedInvestmentInfoVerificationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; cta: string },
    private dialogRef: MatDialogRef<CompletedInvestmentInfoVerificationComponent>,
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
